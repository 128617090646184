import createAxiosRequest from '@utils/createAxiosRequest'
import getRuntimeConfig from '@utils/getRuntimeConfig'

export const fileFormUpload = (endpoint: string, multipartFormDataFile: Blob, formData?: FormData): Promise<any> => {
  const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
  formData = formData || new FormData()
  formData.append('file', multipartFormDataFile)
  return createAxiosRequest({
    method: 'POST',
    url: `${apiBaseUrl}${endpoint}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const multipleFileFormUpload = (endpoint: string, files: Blob[], formData = new FormData()): Promise<any> => {
  const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')

  files.forEach((file, index) => {
    formData.append(`file${index}`, file)
  })

  return createAxiosRequest({
    method: 'POST',
    url: `${apiBaseUrl}${endpoint}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
