import gql from 'graphql-tag'

const organisationInfoGetQuery = gql`
  query organisationInfoGet($registrationNumber: String!) {
    organisationInfoGet(registrationNumber: $registrationNumber)
      @rest(type: "OrganisationInfo", method: "GET", path: "organisation/info/:registrationNumber") {
      name
      registrationNumber
      vatNumber
      addressLine1
      addressLine2
      addressLine3
      systemId
    }
  }
`

export default organisationInfoGetQuery
