import React, { VFC } from 'react'
import styled from '@xstyled/styled-components'
import { keyframes } from 'styled-components'
import { th, ThemeGetterFunc } from '@xstyled/system'
import { appearanceToFontColorMap } from '@constants/Appearance'

const KeyframesCircularDash = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`

const KeyframesCircularRotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const Svg = styled.svg<{ $variant: 'default' | 'sm' }>`
  width: ${({ $variant }): string => ($variant === 'sm' ? '16' : '40')}px;
  height: ${({ $variant }): string => ($variant === 'sm' ? '16' : '40')}px;
  animation: ${KeyframesCircularRotate} 1.4s linear infinite;
`

const Circle = styled.circle`
  animation: ${KeyframesCircularDash} 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;
  stroke: ${({ color }): ThemeGetterFunc => (color ? th.color(color) : th.color('blue2'))};
`

interface SpinnerProps {
  variant?: 'default' | 'sm'
  appearance?: string
}

export const Spinner: VFC<SpinnerProps> = ({ variant = 'default', appearance }) => {
  const color = appearance ? appearanceToFontColorMap[appearance] : 'blue2'

  return (
    <Svg viewBox="22 22 44 44" $variant={variant}>
      <Circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" color={color} />
    </Svg>
  )
}
