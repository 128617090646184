import { Ability, AbilityBuilder, createAliasResolver } from '@casl/ability'
import getRuntimeConfig from '@utils/getRuntimeConfig'
export default class ACl {
  private abilityBuilder = new AbilityBuilder()
  private resolveAction = createAliasResolver({
    adminRead: ['read'],
    adminEdit: ['edit'],
    userEdit: ['edit'],
  })
  private ability: Ability = new Ability(this.abilityBuilder.rules, {
    detectSubjectType: (subject: string | Array<string>): string => (Array.isArray(subject) ? subject[0] : subject),
    resolveAction: this.resolveAction,
  })

  public getAbility(): Ability {
    return this.ability
  }

  public setUserAbility(userId: string | null): void {
    const { can, cannot, rules } = this.abilityBuilder
    can('read', 'UserHome')
    can('editProfile', 'User')

    can('read', 'Audits')
    can('read', 'Baselinker')
    can('read', 'Carriers')
    can('read', 'Complaints')
    can('read', 'Eshops')
    can('read', 'Expeditions')
    can('read', 'Stocks')
    can('read', 'StockAdvices')
    can('read', 'StockMovements')
    can('read', 'StockChanges')
    can('read', 'InboundReceipts')
    can('read', 'MissingStock')
    can('read', 'Notifications')
    can('read', 'OutboundReceipts')
    can('read', 'Organisations')
    can('read', 'Partners')
    can('read', 'Products')
    can('read', 'ReturnReceipts')
    can('read', 'Reservations')
    can('read', 'Suppliers')
    can('read', 'StockAdvices')
    can('read', 'StockChanges')
    can('read', 'StockMovements')
    can('read', 'Stocks')
    can('read', 'Transfers')
    can('read', 'Webhook')

    can('edit', 'Partner')
    can('edit', 'Product')
    can('edit', 'Organisation', (subject: string, i: number, [, extra]: Array<any> = []): boolean => {
      if (!extra?.owner?.id) return false
      return extra?.owner?.id === userId
    })
    can('edit', 'Eshop')
    can('edit', 'StockAdvice')
    can('edit', 'Expedition')
    can('edit', 'Notification')
    can('edit', 'Supplier')
    can('edit', 'Transfer')
    can('edit', 'StockChange')
    can('userEdit', 'Complaint')
    can('edit', 'Webhook')

    this.ability.update(rules)
    console.log('User ability access granted.')
  }

  public setAdminAbility(adminRoles: string[]): void {
    const { can, cannot, rules } = this.abilityBuilder
    let adminLogName = ''

    can('adminRead', 'all')
    can('read', 'Baselinker')
    cannot('adminRead', 'Notifications')
    can('editProfile', 'Admin')
    can('reposition', 'Expedition')

    if (adminRoles.includes('ROLE_SUPERVISOR')) {
      can('adminEdit', 'all')
      cannot('adminEdit', 'Notification')
      can('supervisorEdit', 'Product') // special privilege CS does not have
      adminLogName = 'Super'
    } else if (adminRoles.includes('ROLE_CUSTOMER_SERVICE')) {
      can('adminEdit', 'Product')
      can('adminEdit', 'Complaint')
      can('adminEdit', 'Transfer')
      can('edit', 'StockAdvice')
      can('edit', 'Expedition')
      can('edit', 'Supplier')
      can('edit', 'Partner')
      adminLogName = 'CS'
    }

    this.ability.update(rules)
    console.log(`${adminLogName} Admin ability access granted.`)
  }

  public setReady(): void {
    /**
     * not really acl, but mark that FE is ready to send authorized requests.
     * TODO structure in better way when we remake acl
     */
    const { can, rules } = this.abilityBuilder
    can('aclReady', 'BE')
    this.ability.update(rules)
    console.log('Send Request ability access granted.')
  }
}
