import gql from 'graphql-tag'

const notificationsGetQuery = gql`
  query notificationsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object, $nested: boolean) {
    notificationsGet(
      input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, select: $select, nested: $nested }
    ) @rest(type: "NotificationsResults", method: "POST", path: "notification/list") {
      results
      paging
    }
  }
`

export default notificationsGetQuery
