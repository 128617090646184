import gql from 'graphql-tag'

const carriersAddQuery = gql`
  query carriersAdd($organisationId: number, $carriers: Array) {
    carriersAdd(organisationId: $organisationId, input: { carriers: $carriers })
      @rest(type: "carriersAdd", method: "PUT", path: "organisation/:organisationId/carrier-add") {
      carriers
    }
  }
`
export default carriersAddQuery
