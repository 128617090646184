import { select, put, takeLatest, fork } from 'redux-saga/effects'
import { Store } from 'redux'
import { actions, actionTypes } from './'
import { actionTypes as eshopActionTypes } from '../eshops'
import batchNotifications, {
  runBatchNotifications,
  showBatchDetail,
  removeBatchNotificationByType,
} from './batchNotificationSaga'
import { BaselinkerApi } from '@services/BaselinkerApi'
import { selectors as baselinkerCredentialsSelector } from '@store/baselinkerCredentials'
import { actions as statusRowsActions, selectors as statusRowSelectors } from '@store/statusRows'
import Message from '@typings/entities/Message'
import { t } from '@lingui/macro'

function* clearOldBatch(type: 'product' | 'productStock'): Generator {
  const syncDetailToken = (yield select(baselinkerCredentialsSelector.getSyncDetailToken)) as string
  const statusRows = (yield select(statusRowSelectors.getAll)) as Message[]
  const oldStatus = statusRows.find((status) => status.type === type)

  if (type === 'product') {
    const title = t({
      id: 'baselinker.inventorySyncProductPreparing',
      message: 'Synchronization of products is preparing...',
    })
    yield put(statusRowsActions.push(title, { text: '', intent: 'info', type: 'productPreparing' }, null))
  }

  if (type === 'productStock') {
    const title = t({
      id: 'baselinker.inventorySyncProductStockPreparing',
      message: 'Synchronization of product stocks is preparing...',
    })
    yield put(statusRowsActions.push(title, { text: '', intent: 'info', type: 'productStockPreparing' }, null))
  }

  if (oldStatus) {
    const batchId = oldStatus.batchId
    yield put(statusRowsActions.remove(oldStatus.id))
    yield put(actions.addBatchToSeenList(batchId))
    yield BaselinkerApi(syncDetailToken).batch[type === 'product' ? 'seenBatchProduct' : 'seenBatchProductStock'](batchId)
  }
}

const prepareSyncBatch = (type: 'product' | 'productStock') =>
  function* prepareSyncBatch({ payload }: { payload: boolean }): Generator {
    if (payload) {
      // mark batches notification of selected type as seen and show fe only batch notification Preparing
      yield clearOldBatch(type)
    } else {
      // remove preparing notification for selected type
      yield removeBatchNotificationByType(`${type}Preparing`)
    }
  }

const syncBatchProduct = (store: Store): Generator =>
  function* syncBatch({ payload }) {
    try {
      const syncDetailToken = (yield select(baselinkerCredentialsSelector.getSyncDetailToken)) as string
      yield put(actions.setBaseLinkerProductSyncStatus(true))
      const batch = yield BaselinkerApi(syncDetailToken).batch.batchDetailProduct(payload)
      yield showBatchDetail(store, [batch])
    } catch (e) {
      yield put(actions.setBaseLinkerProductStockSyncStatus(false))
      console.error(e)
    }
  }

const syncBatchProductStock = (store: Store): Generator =>
  function* syncBatch({ payload }) {
    try {
      const syncDetailToken = (yield select(baselinkerCredentialsSelector.getSyncDetailToken)) as string
      yield put(actions.setBaseLinkerProductStockSyncStatus(true))
      const batch = yield BaselinkerApi(syncDetailToken).batch.batchDetailProductStock(payload)
      yield showBatchDetail(store, [batch])
    } catch (e) {
      yield put(actions.setBaseLinkerProductStockSyncStatus(false))
      console.error(e)
    }
  }

const syncBatchAll = (store: Store): Generator =>
  function* syncBatch() {
    try {
      yield runBatchNotifications(store)
    } catch (e) {
      console.error(e)
    }
  }

export default function* watch(store: Store): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.prepareSyncBatchProduct, prepareSyncBatch('product'))
  yield takeLatest(actionTypes.prepareSyncBatchProductStock, prepareSyncBatch('productStock'))
  yield takeLatest(actionTypes.syncBatchProduct, syncBatchProduct(store))
  yield takeLatest(actionTypes.syncBatchProductStock, syncBatchProductStock(store))
  // fetch batch one time and immediately after eshops are available
  yield takeLatest(eshopActionTypes.fetchSucceeded, syncBatchAll(store))
  // fetch batch information in periodic interval
  yield fork(batchNotifications(store))
}
