import gql from 'graphql-tag'

const userUpdateMenuPreferencesQuery = gql`
  query userUpdateMenuPreferences($id: String!, $preferences: [String]) {
    userUpdateMenuPreferences(input: { preferences: $preferences }, id: $id) @rest(method: "PUT", path: "user/:id/preferences") {
      id
      preferences
    }
  }
`

export default userUpdateMenuPreferencesQuery
