import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import { BaselinkerApi } from '@services/BaselinkerApi'

const fetchList = async (_: any, { payload }: Action): Promise<any> => {
  const {
    criteria: { updatedAt, syncDetailToken, lastSyncResult, ...rest },
    limit,
    offset,
  } = payload

  return BaselinkerApi(syncDetailToken)
    .order.orderSyncAggregated({
      limit,
      offset,
      lastSyncResult: lastSyncResult
        ? {
            ...lastSyncResult,
            inWithMultipleOr: lastSyncResult.in?.includes('error') ? true : undefined,
            inWithNull: lastSyncResult.in?.includes('finish') ? true : undefined,
          }
        : undefined,
      from: updatedAt?.gte,
      to: updatedAt?.lt,
      ...rest,
    })
    .catch(console.error)
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
