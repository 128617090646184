import { H1, H3 } from '@designSystem/Typography'
import styled from '@xstyled/styled-components'
import React from 'react'
import { Trans } from '@lingui/react'
import FaIcon from '@mailstep-design-system/icon'

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  text-align: center;
  padding-top: 50px;
`

const Container = styled.div``

const Error = styled.code`
  background-color: lightGray2;
  padding: 10px;
  margin-top: 30px;
  display: block;
`

type Props = {
  error?: Error
}

const Error500Page: React.ComponentType<Props> = ({ error }: Props): JSX.Element => {
  console.error(error)
  return (
    <Wrapper>
      <Container>
        <FaIcon icon="faceMeh" size="90px" fill="red2" />
        <H1>
          <Trans id="common.unexpectedError.title" message="We're sorry, but an unexpected error has occurred." />
        </H1>
        <H3>
          <Trans
            id="common.unexpectedError.promisse"
            message="The bug has already been logged and we are currently working on fixing it."
          />
        </H3>
        {error && <Error>Error: {error?.message || (typeof error === 'string' ? error : '')}</Error>}
      </Container>
    </Wrapper>
  )
}

export default Error500Page
