import { Themes } from '../types'
import defaultTheme from './default'
import light from './light'

const themes: Themes = {
  default: defaultTheme,
  light: light,
}

export default themes
