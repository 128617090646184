import { select, delay, put } from 'redux-saga/effects'
import { selectors as adminSelectors } from '@store/admin'
import { selectors as userSelectors } from '@store/user'
import { actions as statusRowsActions, selectors as statusRowSelectors } from '@store/statusRows'
import { selectors as eshopSelectors } from '@store/eshops'
import { actions as uiActions } from '@store/ui'
import { Store } from 'redux'
import { selectors as baselinkerCredentialsSelector } from '@store/baselinkerCredentials'
import { t } from '@lingui/macro'
import { BaselinkerApi } from '@services/BaselinkerApi'
import { BatchDetail, LastBatchSyncId, BatchDetailStatus } from '@typings/entities/BaseLinkerBatch'
import { WithData } from '@typings/generic'
import { Eshop } from '@typings/entities/Eshop'
import { actions, selectors } from './'
import { displayToast } from '@utils/toast'
import Message from '@typings/entities/Message'
import { DateTime } from 'luxon'

const getAlert = (batch: BatchDetail, eshopId: string, eshopName: string, syncDetailToken: string, { dispatch }: Store) => {
  const handleOnClose = async (notificationId: string) => {
    dispatch(statusRowsActions.remove(notificationId))
    dispatch(actions.addBatchToSeenList(batch.id))
    const endpoint = batch.mailship_warehouse_id ? 'seenBatchProductStock' : 'seenBatchProduct'
    await BaselinkerApi(syncDetailToken).batch[endpoint](batch.id).catch(console.error)
  }

  const handleView = () => {
    const extra = { eshopId, syncDetailToken, type: batch.inventory_id ? 'product' : 'stock' }
    dispatch(uiActions.openQuickAccessDialog('synchronizationResults', undefined, extra))
  }

  const tInventory = t({ id: 'common.ofInventory', message: 'inventory' })
  const tProducts = t({ id: 'common.ofProducts', message: 'products' })
  const tProductStock = t({ id: 'common.ofProductStocks', message: 'product stocks' })
  const type = !!batch.inventory_id ? 'product' : 'productStock'
  const isProduct = type === 'product'

  const alerts: Record<string, Message> = {
    ongoing: {
      message: t({
        id: 'baselinker.inventorySyncProgress',
        message: 'Synchronization {type} for eshop "{eshop}" {id} in progress.',
        values: {
          id: isProduct ? `${tInventory} ID: ${batch.inventory_id}` : '',
          eshop: eshopName,
          type: isProduct ? tProducts : tProductStock,
        },
      }),
      text: `${Math.round((100 * batch.synced) / batch.total)}% (${batch.synced}/${batch.total})`,
      intent: 'info',
      type,
      batchId: batch.id,
    },
    completed: {
      message: t({
        id: 'baselinker.syncSuccess',
        message: 'Synchronization {type} for eshop "{eshop}" {id} was successfuly completed',
        values: {
          id: isProduct ? `${tInventory} ID: ${batch.inventory_id}` : '',
          eshop: eshopName,
          type: isProduct ? tProducts : tProductStock,
        },
      }),
      intent: 'success',
      onClose: handleOnClose,
      actionType: 'button',
      actionLabel: t({ id: 'alert.viewLink', message: 'View' }),
      type,
      batchId: batch.id,
    },
    failed: {
      message: t({
        id: 'baselinker.syncFailed',
        message: 'Synchronization {type} for eshop "{eshop}" {id} failed. Unsynchronized items: {failed}x',
        values: {
          id: isProduct ? `${tInventory} ID: ${batch.inventory_id}` : '',
          eshop: eshopName,
          failed: batch.failed,
          type: isProduct ? tProducts : tProductStock,
        },
      }),
      text: batch.errors ? `(${batch.errors})` : '',
      intent: 'warning',
      onClick: handleView,
      onClose: handleOnClose,
      actionLabel: t({ id: 'alert.viewLink', message: 'View' }),
      actionType: 'button',
      type,
      batchId: batch.id,
    },
  }

  return alerts[batch.status !== BatchDetailStatus.Ongoing && batch.failed ? 'failed' : batch['status']]
}

export function* removeBatchNotificationByType(type: string): Generator {
  const statusRows = (yield select(statusRowSelectors.getAll)) as Message[]
  const toRemove = statusRows.filter((status) => status.type === type)

  for (let i = 0; i < toRemove.length; i++) {
    yield put(statusRowsActions.remove(toRemove[i].id as string))
  }
}

/**
 * For all batches in array show notification
 * Use store for dispatch callbacks for handling onClick in notification
 */
export function* showBatchDetail(store: Store, batches: WithData<BatchDetail>[]): Generator {
  const syncDetailToken = (yield select(baselinkerCredentialsSelector.getSyncDetailToken)) as string
  const selectedEshop = (yield select(baselinkerCredentialsSelector.getSelectedEshop)) as string
  const eshops = (yield select(eshopSelectors.eshops)) as { eshopsData: Eshop[] }
  const eshopName = eshops.eshopsData?.find((eshop: Eshop): boolean => eshop.id === selectedEshop)?.name

  // we cann't show notification without eshop name
  if (!eshopName) return

  try {
    // if user closed the notification but BE not already processed this hiding,
    // we can still keep it hidden on FE only by hidden history evidence
    const batchSeenList = (yield select(selectors.batchSeenList)) as string[]

    for (let i = 0; i < batches.length; i++) {
      // it notification is not on hide list
      if (batches[i] && !batchSeenList?.includes(batches[i].data.id)) {
        const alert = getAlert(batches[i].data, selectedEshop, eshopName, syncDetailToken, store)

        // remove same notification from store before created new one
        const type = batches[i].data.inventory_id ? 'product' : 'productStock'
        yield removeBatchNotificationByType(type)
        yield removeBatchNotificationByType(`${type}Preparing`)

        yield put(statusRowsActions.push(alert.message, alert, null))
      }
    }
  } catch (e: any) {
    console.error(e)
    displayToast({ type: 'error', title: e?.message || 'error' })
  }
}

export function* runBatchNotifications(store: Store): Generator {
  const syncDetailToken = (yield select(baselinkerCredentialsSelector.getSyncDetailToken)) as string

  if (syncDetailToken) {
    try {
      const res = (yield BaselinkerApi(syncDetailToken).batch.lastBatchsyncId()) as WithData<LastBatchSyncId>
      const batches = [
        res.data?.product ? yield BaselinkerApi(syncDetailToken).batch.batchDetailProduct(res.data.product) : null,
        res.data?.stock ? yield BaselinkerApi(syncDetailToken).batch.batchDetailProductStock(res.data.stock) : null,
      ] as WithData<BatchDetail>[]

      const baseLinkerProductSyncStatus = yield select(selectors.baseLinkerProductSyncStatus)
      const baseLinkerProductStockSyncStatus = yield select(selectors.baseLinkerProductStockSyncStatus)

      if (baseLinkerProductSyncStatus !== res.data?.productHasOngoingSync) {
        yield put(actions.setBaseLinkerProductSyncStatus(res.data?.productHasOngoingSync))
      }
      if (baseLinkerProductStockSyncStatus !== res.data?.stockHasOngoingSync) {
        yield put(actions.setBaseLinkerProductStockSyncStatus(res.data?.stockHasOngoingSync))
      }

      yield showBatchDetail(store, batches)
    } catch (e: any) {
      console.error(e)
      displayToast({ type: 'error', title: e?.message || 'error' })
    }
  }
}

const batchNotifications = (store: Store) =>
  function* batchNotification(): Generator {
    while (true) {
      try {
        const isAdminLogedIn = yield select(adminSelectors.isAdminLogedIn)
        const isUserLogedIn = yield select(userSelectors.isUserLogedIn)

        if (isAdminLogedIn || isUserLogedIn) {
          yield runBatchNotifications(store)
        }
      } catch (e: any) {
        console.error(e)
        displayToast({ type: 'error', title: e?.message || 'error' })
      }
      yield delay(15000)
    }
  }

export default batchNotifications
