import { ApolloClient } from '@apollo/client'
import get from 'lodash/fp/get'
import { takeLatest, select, put, getContext, call } from 'redux-saga/effects'
import createFetchSaga from '@utils/store/createFetchSaga'
import reportExpeditionGetQuery from '@queries/reportExpeditionGetQuery'
import { Report, DeliveryRate } from '@typings/entities/Report'
import { actionTypes as organisationActionTypes, selectors as organisationSelectors } from '@store/organisation'
import { actionTypes as expeditionActionsTypes } from '@store/expeditions'
import { DateTime } from 'luxon'
import { actionTypes, actions } from './index'

const getCount = get('count')
const getResults = get('data.reportExpeditionGet.results')

const getRate = (data: Report[]): DeliveryRate => ({
  delivered: getCount(data.find((item) => !!item.delivered)) || 0,
  undelivered: getCount(data.find((item) => !item.delivered)) || 0,
})

const expeditionStates = [
  'on_hold',
  'waiting_for_goods',
  'awaiting_processing',
  'waiting_for_the_carrier',
  'carrier_picked_up',
  'incorrect',
  'stock_ok',
  'take_out',
  'completion',
  'new',
  'waiting_for_cancel',
]

const fillStatuses = () => expeditionStates.reduce((acc, curr) => ({ ...acc, [curr]: 0 }), {})

const fetchExpeditionReport = async (organisation, client) => {
  const response = await client
    .query({
      query: reportExpeditionGetQuery,
      variables: {
        select: ['count', 'status', 'eshop'],
        criteria: {
          status: { in: expeditionStates },
          ...(organisation ? { organisation: { eq: organisation } } : {}),
        },
      },
    })
    .then(getResults)

  return response
}

function* loadStatusStats(action: Action): Generator {
  yield put(actions.setSEisLoading())

  const organisation: string = yield select(organisationSelectors.organisation)
  const client: ApolloClient<any> = yield getContext('@apolloClient')

  const grouppedResponse = {
    total: fillStatuses(),
  }

  const response = yield call(fetchExpeditionReport, organisation, client)

  if (response) {
    response.forEach((stat) => {
      if (!grouppedResponse[stat.eshop]) grouppedResponse[stat.eshop] = fillStatuses()
      grouppedResponse[stat.eshop][stat.status] += stat.count
      grouppedResponse.total[stat.status] += stat.count
    })
  }

  yield put(actions.setStatusExpeditions(grouppedResponse))
}

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const criteria = {
    ...(action.payload.eshop ? { eshop: { in: [action.payload.eshop] } } : {}),
    ...(action.payload.organisation ? { organisation: { in: [action.payload.organisation] } } : {}),
  }
  const promises = [
    // delivery rate for last day
    client
      .query({
        query: reportExpeditionGetQuery,
        variables: {
          select: ['count', 'delivered'],
          criteria: {
            createdAt: {
              gt: DateTime.local().minus({ days: 1 }).toFormat('yyyy-LL-dd'),
              lt: DateTime.local().toFormat('yyyy-LL-dd'),
            },
            ...criteria,
          },
        },
      })
      .then(getResults),

    // delivery rate for last week
    client
      .query({
        query: reportExpeditionGetQuery,
        variables: {
          select: ['count', 'delivered'],
          criteria: {
            createdAt: {
              gt: DateTime.local().minus({ days: 7 }).toFormat('yyyy-LL-dd'),
              lt: DateTime.local().toFormat('yyyy-LL-dd'),
            },
            ...criteria,
          },
        },
      })
      .then(getResults),

    // delivery rate for last month
    client
      .query({
        query: reportExpeditionGetQuery,
        variables: {
          select: ['count', 'delivered'],
          criteria: {
            createdAt: {
              gt: DateTime.local().minus({ months: 1 }).toFormat('yyyy-LL-dd'),
              lt: DateTime.local().toFormat('yyyy-LL-dd'),
            },
            ...criteria,
          },
        },
      })
      .then(getResults),

    // sales for line chart
    client
      .query({
        query: reportExpeditionGetQuery,
        variables: {
          select: ['count', 'createdDate'],
          sort: [{ field: 'createdDate' }],
          criteria: {
            createdAt: { gt: DateTime.local().minus({ years: 1 }).toFormat('yyyy-LL-dd') },
            ...criteria,
          },
        },
      })
      .then(getResults),
  ]

  try {
    const [deliveryRateLastDay, deliveryRateLastWeek, deliveryRateLastMonth, sales] = await Promise.all(promises)

    const deliveryRates = {
      lastDay: getRate(deliveryRateLastDay),
      lastWeek: getRate(deliveryRateLastWeek),
      lastMonth: getRate(deliveryRateLastMonth),
    }

    return { data: { deliveryRates, sales } }
  } catch (e) {
    console.log(e)
    return {}
  }
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return // dont run on SSR
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  // conditions to refresh expedition status stats:
  yield takeLatest(organisationActionTypes.setOrganisation, loadStatusStats)
  yield takeLatest(expeditionActionsTypes.createExpedition.fetchSucceeded, loadStatusStats)
  yield takeLatest(expeditionActionsTypes.editExpedition.fetchSucceeded, loadStatusStats)
  yield takeLatest(expeditionActionsTypes.cancelExpedition.fetchSucceeded, loadStatusStats)
  yield takeLatest(expeditionActionsTypes.rollbackExpedition.fetchSucceeded, loadStatusStats)
  yield takeLatest(expeditionActionsTypes.sendToWms.fetchSucceeded, loadStatusStats)
  yield takeLatest(expeditionActionsTypes.processToWms.fetchSucceeded, loadStatusStats)
  yield takeLatest(expeditionActionsTypes.sendAllToWms.fetchSucceeded, loadStatusStats)
  yield takeLatest(expeditionActionsTypes.importExpeditions.fetchSucceeded, loadStatusStats)
}
