import gql from 'graphql-tag'

const loginQuery = gql`
  query login($login: String!, $password: String!) {
    login(input: { login: $login, password: $password }) @rest(type: "AdminToken", method: "POST", path: "login/admin") {
      token
      refreshToken
    }
  }
`

export default loginQuery
