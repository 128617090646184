import gql from 'graphql-tag'

const organisationJoinUsersToOrganisationQuery = gql`
  query organisationJoinUsersToOrganisation($organisationId: number, $users: Object) {
    organisationJoinUsersToOrganisation(organisationId: $organisationId, input: { users: $users })
      @rest(type: "JoinUsersToOrganisationResult", method: "PUT", path: "organisation/:organisationId/user-add") {
      data
    }
  }
`

export default organisationJoinUsersToOrganisationQuery
