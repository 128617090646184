import { messages as messagesEn } from './en/messages.js'
import { messages as messagesCs } from './cs/messages.js'
import { messages as messagesRu } from './ru/messages.js'
import { messages as messagesSk } from './sk/messages.js'
import { messages as messagesEs } from './es/messages.js'
import { messages as messagesIt } from './it/messages.js'
import { en, cs, es, it, ru, sk } from 'make-plural/plurals'

export const catalogs = { en: messagesEn, cs: messagesCs, ru: messagesRu, sk: messagesSk, es: messagesEs, it: messagesIt }
export const plurals = { en, cs, es, it, ru, sk }
