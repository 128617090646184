import { WarehouseAsNested } from '@typings/entities/Warehouse'
import { SupplierAsNested } from '@typings/entities/Supplier'
import { PartnerAsNested } from '@typings/entities/Partner'
import { OrganisationAsNested } from '@typings/entities/Organisation'

export type EshopAsNested = {
  id: string
  name: string
  active: boolean
  waitBeforeProcessing?: boolean
  editBeforeProcessing?: boolean
}

export type BareEshop = EshopAsNested & {
  createdAt?: string
  changedAt?: string
}

export type Eshop = BareEshop & {
  organisation: OrganisationAsNested
  defaultWarehouse: WarehouseAsNested
  warehouses: WarehouseAsNested[]
  suppliers: SupplierAsNested[]
  partners: PartnerAsNested[]
}

export type EshopApi = BareEshop & {
  organisation: string
  defaultWarehouse: string | null
  warehouses: string[]
  suppliers: string[]
  partners: string[]
}

export type BaselinkerCarrierMapingItem = {
  carrier: string
  carrierService: string
  baselinkerCourierCode: string
  baselinkerCourierName: string
}

export type BaselinkerCredentials = {
  token: string
  syncDetailToken: string
  organisation?: string
  warehouseMapping: {
    mailShipWarehouse: string
    baseLinkerWarehouse: string
  }[]
  expeditionStatusMapping: {
    mailShipExpeditionStatus: string
    baseLinkerOrderStatusId: string | null
  }[]
  carrierMapping: BaselinkerCarrierMapingItem[]
}

export const BASELINKER_CORRUPTED_ERROR = 422
