import { Theme } from '../types'

const lightTheme: Theme = {
  colors: {
    primary: '#219615',
    warning: 'purple',
  },
}

export default lightTheme
