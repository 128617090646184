import { Theme } from '../types'

// todo improve the theme use, especially the colors
// todo this file should be split and moved

const defaultTheme: Theme = {
  breakpoints: {
    // same as xstyled default
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    white: '#FFFFFF',
    bgLightGray: '#FAFBFC',
    bgLightGray1: '#F1F5F9',
    lightGray1: '#F5F7FA',
    lightGray2: '#EFF3F8',
    lightGray3: '#D4D8DE',
    lightGray4: '#B9BFC9',
    lightGray5: '#A3B3C1',
    lightGray6: '#D2D8DF',
    lightGray7: '#E7EBEF',
    gray: '#8594A5',
    gray1: '#7A90A1',
    gray2: '#9AA1A7',
    gray3: '#5F6C76',
    gray4: '#455665',
    gray5: '#647483',
    blue1: '#2C4052',
    blue2: '#22394E',
    blue3: '#162C3F',
    blue4: '#0C1F2F',
    red1: '#DB2B19',
    red2: '#E94324',
    red3: '#991E12',
    yellow1: '#FFD702',
    yellow2: '#F2E871',
    green: '#13CE62',
    darkGreen: '#00A445',

    textPrimary: '#091E42',
    textTertiary: '#7A869A',
    typoPrimary: '#22394E',

    /** Flags */
    neutral300: '#5E6C84',
    neutral20: '#F1F5F9',
    blue80: '#0747A6',
    blue70: '#0052CC',
    blue20: '#DCEFFF',
    teal90: '#035361',
    teal60: '#00B1D0',
    teal20: '#E6FCFF',
    green80: '#0E6027',
    green60: '#00A445',
    green20: '#DEFBE6',
    orange80: '#9E4800',
    orange60: '#FF9800',
    orange20: '#FFF3E0',
    yellow60: '#FFE600',
    yellow20: '#FFF9C4',
    magenta80: '#9F1853',
    magenta60: '#EE5396',
    magenta20: '#FCF1F7',
    purple80: '#403294',
    purple60: '#6554C0',
    purple20: '#F0EDFF',
    red80: '#B71C1C',
    red60: '#DB2B19',
    red20: '#FFEBE6',

    /** Alert */
    dangerColor: '#E32C1E',
    red10: '#FFF6F4',
    yellow70: '#FFD702',
    yellow10: '#FFFDE8',
    successColor: '#24A148',
    green10: '#F2FFF5',
    blue60: '#0065FF',
    blue10: '#F3F9FF',
  },
  fonts: {
    primary: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
  fontLinks: ['https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap'],
  fontSizes: ['10px', '12px', '14px', '16px', '18px', '21px', '24px', '32px'],
  lineHeights: [1.7],
  fontWeights: {
    default: null,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  letterSpacings: {
    default: null,
    sm: '2px',
    md: '4px',
    lg: '8px',
  },
  borders: {
    slim: '1px solid',
    mediumSlim: '2px solid',
    medium: '3px solid',
    mediumThick: '6px solid',
    thick: '10px solid',
  },
  // ...........................
  radii: {
    sm: '2px',
    md: '4px',
    ml: '6px',
    lg: '8px',
    xl: '16px',
  },
  shadows: {
    boxShadow: '0px 5px 60px rgba(0, 0, 0, 0.1)',
    dropShadow: '0px 5px 40px rgba(0, 0, 0, 0.1)',
    inputFocusBoxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.15)',
    dropdownMenuShadow: '0 5px 25px rgba(0, 0, 0, 0.1)',
    tooltipBoxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
    cardShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.15)',
    // redesign:
    dialogShadow: '1px 4px 7px rgba(0, 0, 0, 0.4)',
    tooltipShadow: '0px 1px 4px 0 #B1B8C3', // color is grayIII
    headerShadow: '1px 2px 3px #00000029',
    headerShadowB: '1px 1px 3px #00000029',
    headerShadowNotifications: '1px 1px 2px #00000029',
    cornerDialogShadow: '1px 1px 3px 1px #00000029',
  },
  space: [0, 4, 8, 16, 24, 48, 96, 144, 192, 240], // same as xstyled default
  transitions: {
    simpleLong: 'all 2s',
    inputTransition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  },
  zIndices: [0, 1, 2, 3, 5, 30, 40, 100, 200, 300, 9999],
}

export default defaultTheme
