import createAxiosRequest from '@utils/createAxiosRequest'
import getRuntimeConfig from '@utils/getRuntimeConfig'

const fileSimpleDownload = (endpoint: string, defaultFilename: string): Promise<any> => {
  const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
  const url = `${apiBaseUrl}${endpoint}`
  let fileName = defaultFilename
  return createAxiosRequest({
    url,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      if (response.status === 401) {
        throw new Error('Error: unauthorized.')
      }
      const match = response.headers['content-disposition']?.match(/filename=(\")?([^;]*)\1/)
      if (match) fileName = match[2]
      return response
    })
    .then(({ data }) => saveAs(data, fileName)) // this will perform download
}

export const fileSimpleDownloadBase64 = (endpoint: string): Promise<string> => {
  const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
  const url = `${apiBaseUrl}${endpoint}`
  return createAxiosRequest({ url, method: 'GET', responseType: 'arraybuffer' })
    .then((response) => {
      if (response.status === 401) {
        throw new Error('Error: unauthorized.')
      }
      return response
    })
    .then(({ data }) => Buffer.from(data, 'binary').toString('base64'))
}

export default fileSimpleDownload
