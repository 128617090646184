import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import getAuthorizationToken from '@utils/getAuthorizationToken'
import { getLanguage } from '@utils/language'

const createAxiosRequest = async <T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  const token = getAuthorizationToken()
  const language = getLanguage()
  const headers = {
    authorization: `Bearer ${token}`,
  }
  if (language) {
    headers['Accept-Language'] = language
  }
  try {
    return await axios.request({
      ...config,
      headers: {
        ...headers,
        ...config.headers,
      },
    })
  } catch (err) {
    // common error handling
    if (err?.response?.data) {
      throw err
    }
    // network error handling
    throw { response: { data: { message: err.message } } }
  }
}

export default createAxiosRequest
