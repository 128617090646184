import produce from 'immer'
import getOr from 'lodash/fp/getOr'
import reduceReducers from 'reduce-reducers'
import createFetchModule from '@utils/store/createFetchModule'

export const moduleName = 'baselinkerCredentials'
const fetchModule = createFetchModule('@store/BaselinkerCredentials', 'LOAD', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  setSelectedEshop: `@store/UI/SET_BASELINKER_ESHOP`,
}

const ownReducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setSelectedEshop:
        draft.eshopId = action?.payload
        break
    }
  })
}

export default reduceReducers(fetchModule.reducer, ownReducer)

export const actions = {
  ...fetchModule.actions,
  setSelectedEshop: (eshopId: string) => ({ type: actionTypes.setSelectedEshop, payload: eshopId }),
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
  getSelectedEshop: (state: State): string => getOr(null, 'eshopId', state?.baselinkerCredentials),
  getSyncDetailToken: (state: State): string => getOr(null, 'syncDetailToken', state?.baselinkerCredentials?.data),
}
