import gql from 'graphql-tag'

const adminUpdateQuery = gql`
  query adminUpdate(
    $id: String!
    $location: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $active: Boolean
    $oldPassword: String
    $newPassword: String
  ) {
    adminUpdate(
      input: {
        location: $location
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        active: $active
        oldPassword: $oldPassword
        newPassword: $newPassword
      }
      id: $id
    ) @rest(method: "PUT", path: "admin/:id") {
      id
      location
      firstName
      lastName
      email
      phone
      active
    }
  }
`

export default adminUpdateQuery
