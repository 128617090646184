import { loadableReady } from '@loadable/component'
import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { Provider as ReduxProvider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import App from './App'
import createStore from './store/create'
import createApolloClient from './utils/createApolloClient'
import getRuntimeConfig from './utils/getRuntimeConfig'
import history from './utils/history'

if (getRuntimeConfig('isProduction')) {
  const dsn = getRuntimeConfig('FRONTEND__SENTRY_DSN')
  if (!dsn) console.warn('Sentry credentials are missing in production mode! Please set FRONTEND__SENTRY_DSN.')
  Sentry.init({
    dsn,
    environment: getRuntimeConfig('FRONTEND__SENTRY_ENV'),
    release: getRuntimeConfig('APP_VERSION'),
    maxBreadcrumbs: 25,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      /Loading chunk [\d]+ failed/,
      'Non-Error exception captured with keys: forward, graphQLErrors, networkError',
    ],
  })
} else {
  window.localStorage.debug = getRuntimeConfig('DEBUG')
}

declare global {
  interface Window {
    __PRELOADED_STATE__: object | undefined
  }
}

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__
// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__

const store = createStore(preloadedState, history)
const client = createApolloClient(store)
store.setSagaContext({ apolloClient: client })
const persistor = persistStore(store)

// loadableReady can be called only with production compilation that's code-splitted, on dev mode call immediately
const onDomReady = process.env.NODE_ENV === 'production' ? loadableReady : (cb: Function): void => cb()
onDomReady(() => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const domNode = document.getElementById('root')!

  return hydrateRoot(
    domNode,
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <ConnectedRouter history={history}>
            <Router history={history}>
              <App />
            </Router>
          </ConnectedRouter>
        </ApolloProvider>
      </PersistGate>
    </ReduxProvider>,
  )
})

if (module.hot) {
  // eslint-disable-line
  module.hot.accept() // eslint-disable-line
}
