const isBrowser = typeof window !== 'undefined'

type TokenType = 'user' | 'admin'

/**
 * On client side we prefer localStorage over redux, because initialization into takes some time
 * and therefore may occur a moment, when we encounter request with no token initialized
 */
export const getTokenFromLocalStorage = (type: TokenType): string | undefined => {
  if (type === 'user') return localStorage?.userToken
  if (type === 'admin') return localStorage?.adminToken
  throw new Error(`Unknown token type "${type}"`)
}

export const getTokenFromState = (type: TokenType, state?: State): string | undefined => {
  if (type === 'user') return state?.user?.auth?.token
  if (type === 'admin') return state?.admin?.auth?.token
  throw new Error(`Unknown token type "${type}"`)
}

const getAuthorizationToken = (type?: TokenType, state?: State): string | undefined => {
  let token: string | undefined
  if (isBrowser) {
    const tokenType = type ? type : window?.location?.pathname.includes('/admin') ? 'admin' : 'user'
    token = getTokenFromLocalStorage(tokenType)
  }
  if (!token) {
    const tokenType = type ? type : state?.admin?.login ? 'admin' : 'user'
    token = getTokenFromState(tokenType, state)
  }
  return token
}

export default getAuthorizationToken
