import React from 'react'
import { x, BoxProps } from '@xstyled/styled-components'

// TODO fix ad use or delete this component
// This component will support translations, etc.
export type TextProps = BoxProps & {
  children?: string | number | JSX.Element | (JSX.Element | undefined)[]
  fontSize?: number
  fontWeight?: string
  variant?: 'normal' | 'medium' | 'semiBold' | 'bold'
}

const Text = (props: TextProps): JSX.Element => <x.div as="span" {...props} />

export default Text
