// import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject, ApolloLink } from 'apollo-boost'
import { ApolloClient, NormalizedCacheObject, ApolloLink } from '@apollo/client'
import { selectors as routerSelectors } from '@store/connectedRouter'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { RestLink } from 'apollo-link-rest'
import { onError } from 'apollo-link-error'
import fetch from 'isomorphic-unfetch'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import getAuthorizationToken from '@utils/getAuthorizationToken'
import { getLanguage } from '@utils/language'
import * as Sentry from '@sentry/browser'

declare global {
  interface Window {
    __APOLLO_STATE__: NormalizedCacheObject
  }
}

// fix SSR missing Headers
if (global.Headers == null) {
  const fetch = require('node-fetch') // eslint-disable-line @typescript-eslint/no-var-requires
  global.Headers = fetch.Headers
}

const noTokenEndpoints = ['/api/refresh-token', '/api/login/admin', '/api/login/user']

const createLink = (store: any): ApolloLink => {
  const isBrowser = typeof window !== 'undefined'
  const uri = isBrowser ? getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL') : process.env.FRONTEND__GRAPHQL_ENDPOINT_URL_SERVER
  // const httpLink = new HttpLink({
  //   uri, // Server URL (must be absolute)
  //   // Use fetch() polyfill on the server
  //   fetch: !isBrowser ? fetch : undefined,
  // })
  const httpLink = new RestLink({
    uri: uri || '/', // Server URL (must be absolute)
    // Use fetch() polyfill on the server
    //customFetch: !isBrowser ? fetch : undefined,
    customFetch: (request: string, params: RequestInit): Promise<Response> => {
      const state = store.getState()
      const pathname = routerSelectors.pathnameSelector(state)
      const tokenType = request === '/api/me' || /^\/user/.test(pathname) ? 'user' : 'admin'
      if (params?.headers) {
        const uiLanguage = getLanguage()
        if (uiLanguage) params.headers.append('Accept-Language', uiLanguage)
        const token = getAuthorizationToken(tokenType, store) // eslint-disable-line prefer-const
        if (noTokenEndpoints.includes(request)) {
          // do not append Bearer token!
        } else if (token) {
          params.headers.append('Authorization', `Bearer ${token}`)
        }
      }
      return fetch(request, params)
    },
  })
  const errorLink = onError((err) => {
    // Raven.captureException(error) // TODO: sentry
    if (err.graphQLErrors) {
      err.graphQLErrors.forEach((error) => {
        const { message, locations, path } = error
        store.dispatch({
          type: '%%apollo%%ERROR',
          payload: { message, locations, path, type: 'graphQLError' },
        })
      })
    }

    if (err.networkError) {
      /**
       * TODO ?! - this probably should be refactored so that network error is dispatched
       * somewhere in rejected promise of createFetchSaga because the we could suppress
       * default error messages simply anywhere where custom error is dispatched rather
       * then preemptively as untyped query param and risk introducing inconsistencies
       */
      const suppressError = err?.operation?.getContext()?.suppressError
      if (!suppressError || !suppressError(err?.networkError?.result, err?.networkError?.statusCode)) {
        // debugging 401
        if (err?.networkError?.statusCode == 401 && isBrowser) {
          if (localStorage) Sentry.setContext('localStorage', { ...localStorage, 'persist:root': null })
          Sentry.setContext('adminState', store.getState()?.admin)
          Sentry.setContext('userState', store.getState()?.user)
          Sentry.captureException(err)
        }

        // Following operations has custom error handling for status code 409
        const ignorredOperations = ['reservationUnbook', 'reservationMove']

        if (!(err?.networkError?.statusCode === 409 && ignorredOperations.indexOf(err.operation.operationName) !== -1)) {
          store.dispatch({
            type: '%%apollo%%ERROR',
            payload: { error: err.networkError.message, type: 'networkError' },
          })
        }
      }
    }
  })
  const link = errorLink.concat(httpLink)
  return link
}

export default function createClient(store: any): ApolloClient<NormalizedCacheObject> {
  const isBrowser = typeof window !== 'undefined'
  const client = new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    ssrForceFetchDelay: 100,
    link: createLink(store),
    cache: isBrowser ? new InMemoryCache().restore(window.__APOLLO_STATE__) : new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })

  return client
}
