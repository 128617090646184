import gql from 'graphql-tag'

const refreshTokenQuery = gql`
  query newToken($refreshToken: String!) {
    newToken(input: { refreshToken: $refreshToken }) @rest(type: "NewToken", method: "POST", path: "refresh-token") {
      token
      exp
    }
  }
`

export default refreshTokenQuery
