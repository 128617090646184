import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import carriersGetQuery from '@queries/carriersGetQuery'
import carriersAddQuery from '@queries/carriersAddQuery'
import carriersRemoveQuery from '@queries/carriersRemoveQuery'
import organisationGetQuery from '@queries/organisationGetQuery'

const fetchListObsolete = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: carriersGetQuery, variables: action.payload }).then(({ data }) => ({ data: data?.carriersGet }))
}

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: carriersGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.carriersGet?.results }))
}

const fetchSetCarriers = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const { organisationId, carrierIds } = action.payload

  const result = await client
    .query({ query: organisationGetQuery, variables: { id: organisationId } })
    .then(({ data }) => ({ data: data?.organisationGet }))
  const currentCarriesIds: string[] = result?.data.carriers

  const carriersToAdd = carrierIds.filter((carrier) => !currentCarriesIds.includes(carrier))
  const carriersToRemove = currentCarriesIds.filter((carrier) => !carrierIds.includes(carrier))

  let addPromise: Promise<any> = Promise.resolve([])
  if (carriersToAdd?.length) {
    addPromise = client
      .query({
        query: carriersAddQuery,
        variables: {
          organisationId: organisationId,
          carriers: carriersToAdd,
        },
      })
      .then(({ data }) => ({ data: data?.carriersUpdate }))
  }

  let removePromise: Promise<any> = Promise.resolve([])
  if (carriersToRemove?.length) {
    removePromise = client
      .query({
        query: carriersRemoveQuery,
        variables: {
          organisationId: organisationId,
          carriers: carriersToRemove,
        },
      })
      .then(({ data }) => ({ data: data?.carriersUpdate }))
  }

  return Promise.all([addPromise, removePromise])
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchListObsolete))
  yield takeLatest(actionTypes.loadList.run, createFetchSaga(actionTypes.loadList.run, fetchList))
  yield takeLatest(actionTypes.setCarriers.run, createFetchSaga(actionTypes.setCarriers.run, fetchSetCarriers))
}
