import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import getOr from 'lodash/fp/getOr'
import { t } from '@lingui/macro'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import complaintsGetQuery from '@queries/complaintsGetQuery'
import expeditionsGetQuery, { expeditionsGetFiltersQuery } from '@queries/expeditionsGetQuery'
import toSelectOption from '@utils/toSelectOption'
import fetchAsyncExport from '@utils/fetchAsyncExport'
import { displayToast } from '@utils/toast'
import { sagaDebounce } from '@store/helpers'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  // MF-3205 required the option to filter complaints with no resolution
  // but there is no 'no_resolution' state in our API and null is returned instead
  // that is the reason for { null: true } condition
  // which can not be used together with { in: [] } condition
  const payload = action.payload
  const resolutions = getOr([], 'criteria.resolution.in', payload)
  if (resolutions.length && resolutions.includes('no_resolution')) {
    payload.criteria.resolution = { null: true }
    if (resolutions.length > 1) {
      displayToast({
        type: 'info',
        text: t({
          id: 'complaints.filters.noResolutionSelected',
          message: '"No resolution" filter selected. Other selected resolution states will be ingored.',
        }),
      })
    }
  }

  const complaints = await client
    .query({
      query: complaintsGetQuery,
      variables: payload,
    })
    .then(({ data }) => ({ data: data?.complaintsGet }))

  const expeditionIds = (complaints?.data?.results || []).map((complaint) => complaint.expedition.id)
  if (expeditionIds.length < 1) return complaints

  const expeditionsMicro = await client
    .query({
      query: expeditionsGetQuery,
      variables: {
        select: ['eshop', 'id'],
        criteria: { id: { in: expeditionIds } },
      },
    })
    .then(({ data }) => data?.expeditionsGet)

  complaints?.data?.results?.forEach((complaint, index) => {
    complaints.data.results[index].expedition = {
      ...complaint.expedition,
      ...expeditionsMicro.results.find((expedition) => expedition.id == complaint.expedition.id),
    }
  })
  return complaints
}

const fetchFilters = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const organisationId = action.payload
  const expeditionsFilters = await client
    .query({
      query: expeditionsGetFiltersQuery,
      variables: {
        path: organisationId ? `/${organisationId}` : '',
      },
    })
    .then(({ data }) => data?.expeditionsGetFilters)

  return {
    data: {
      deliveryCountry: expeditionsFilters.countries.map(toSelectOption('code', 'name')),
      carrier: expeditionsFilters.carriers.map(toSelectOption('id', 'name')),
      carrierServices: expeditionsFilters.carrierServices.map(toSelectOption('id', 'name')),
    },
  }
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.loadAsyncFilters.run, createFetchSaga(actionTypes.loadAsyncFilters.run, fetchFilters))
  yield takeLatest(actionTypes.export.run, createFetchSaga(actionTypes.export.run, fetchAsyncExport('complaint')))
}
