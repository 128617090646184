import gql from 'graphql-tag'

const notificationSettingsGetQuery = gql`
  query notificationSettingsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object, $nested: boolean) {
    notificationSettingsGet(
      input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, select: $select, nested: $nested }
    ) @rest(type: "NotificationSettingResult", method: "POST", path: "notification-setting/list") {
      results
      paging
    }
  }
`

export default notificationSettingsGetQuery
