import { ExpeditionAsNested } from '@typings/entities/Expedition'
import { AccountAsNested } from '@typings/entities/User'
import { OrganisationAsNested } from '@typings/entities/Organisation'
import {
  ComplaintReasonTrans,
  ComplaintResolutionTrans,
  ComplaintStatusTrans,
  ComplaintTypeTrans,
} from '@typings/enums/Complaint'

export enum QuickFilter {
  all = 'all',
  mine = 'mine',
  unassigned = 'unassigned',
}

export type QuickFilterType = keyof typeof QuickFilter

export const waitingStatuses = [
  'waiting_for_reporter',
  'waiting_for_resolver',
  'waiting_for_resolver_log',
  'waiting_for_cs',
  'waiting_for_ms',
  'waiting_for_carrier',
]

// @deprecated

type BareComplaint = {
  id: string
  internalId: string
  createdAt: string
  changedAt?: string
}

export type Complaint = Partial<BareComplaint> & {
  createdBy?: string
  reporter?: string
  resolver?: string
  organisation: string
  expedition: string
  status: keyof typeof ComplaintStatusTrans
  type?: 'expedition'
  reason: keyof typeof ComplaintReasonTrans
  resolution: keyof typeof ComplaintResolutionTrans
  description: string
  attachments?: string[]
  actions?: string[]
}

export type ComplaintNested = BareComplaint & {
  createdBy: AccountAsNested
  reporter: AccountAsNested
  resolver: AccountAsNested // this one is Admin though, not "account"
  organisation: OrganisationAsNested
  expedition: ExpeditionAsNested
  status: keyof typeof ComplaintStatusTrans
  type: keyof typeof ComplaintTypeTrans
  reason: keyof typeof ComplaintReasonTrans
  resolution: keyof typeof ComplaintResolutionTrans
}

export type ComplaintAttachment = {
  id: string
  complaint: string
  name: string
  complaintAction?: string
  originalName: string
  description: string
  mimeType: string
  size: number
  uploadedBy: AccountAsNested
  url: string
  createdAt: string
  changedAt?: string
  uploadedAt: string
}

export const ComplaintActionType = {
  workflow: 'workflow',
  comment: 'comment',
}

export type ComplaintAction = {
  id: string
  complaint: string
  type: keyof typeof ComplaintActionType
  newStatus?: keyof typeof ComplaintStatusTrans
  note?: string
  createdBy: AccountAsNested
  createdAt: string
  changedAt?: string
  deleted: boolean
  internal: false
  attachments: ComplaintAttachment[]
}

export type ComplaintActionComment = {
  complaint?: string
  note?: ComplaintAction['note']
  internal?: ComplaintAction['internal']
  wait?: boolean
}

export type ComplaintDetail = Omit<ComplaintNested, 'organisation' | 'expedition'> & {
  organisation: string
  expedition: string
  description: string
  attachments: ComplaintAttachment[]
  actions: ComplaintAction[]
  value?: string
  currency?: string
  status: string
}

export type ComplaintDetailsUpdate = {
  id: string
  reporter?: string
  resolver?: string
  reason: keyof typeof ComplaintReasonTrans
  description?: string
  resolutionDetail?: string
  currency?: string
  value?: number
}
