import getRuntimeConfig from '@utils/getRuntimeConfig'
import mustache from '@utils/mustache'

const syncDetailURL = 'sync-detail/'

const orderAggregated = `order-aggregated`
const orderDetails = `order`
const orderRetry = `order-retry`

const productAggregated = `product-aggregated`
const productDetails = `product`
const productRetry = `product-retry`

const lastBatchId = `last-batch-sync-id`
const batchProductStock = `batch-product-stock/{id}`
const batchProduct = `batch-product/{id}`
const seenBatchProductStock = `batch-product-stock/{id}/seen`
const seenBatchProduct = `batch-product/{id}/seen`

export const getBaselinkerRoutes = (params?: Record<string, string>) => {
  const url = getRuntimeConfig('BASELINKER_MIDDLEWARE_URL')

  if (!url) {
    console.error('Error: Missing baselinker middleware url')
  }

  const syncURL = new URL(syncDetailURL, url).href
  return {
    order: {
      aggregated: new URL(orderAggregated, syncURL).href,
      details: new URL(orderDetails, syncURL).href,
      retry: new URL(orderRetry, syncURL).href,
    },
    product: {
      aggregated: new URL(productAggregated, syncURL).href,
      details: new URL(productDetails, syncURL).href,
      retry: new URL(productRetry, syncURL).href,
    },
    batch: {
      lastBatchId: new URL(lastBatchId, syncURL).href,
      productStock: new URL(mustache(batchProductStock, params), syncURL).href,
      product: new URL(mustache(batchProduct, params), syncURL).href,
      seenBatchProductStock: new URL(mustache(seenBatchProductStock, params), syncURL).href,
      seenBatchProduct: new URL(mustache(seenBatchProduct, params), syncURL).href,
    },
  }
}
