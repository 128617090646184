import gql from 'graphql-tag'

const adminUpdateMenuPreferencesQuery = gql`
  query adminUpdateMenuPreferences($id: String!, $preferences: [String]) {
    adminUpdateMenuPreferences(input: { preferences: $preferences }, id: $id)
      @rest(method: "PUT", path: "admin/:id/preferences") {
      id
      preferences
    }
  }
`

export default adminUpdateMenuPreferencesQuery
