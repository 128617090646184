import { saveAs } from 'file-saver'
import getAuthorizationToken from '@utils/getAuthorizationToken'
import { displayToast } from './toast'
import { t } from '@lingui/macro'

const fetchFileAuthorized = async (fileUrl: string, fileName = 'file'): Promise<any> => {
  const token = getAuthorizationToken()
  return await fetch(fileUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status === 401) {
        throw new Error('Error: unauthorized.')
      }
      return response
    })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, fileName))
    .catch(() => {
      displayToast({ type: 'error', text: t({ id: 'fileDownload.error', message: 'File download failed.' }) })
    })
}

export default fetchFileAuthorized
