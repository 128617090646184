import reduceReducers from 'reduce-reducers'
import produce from 'immer'
import getOr from 'lodash/fp/getOr'
import createFetchModule from '@utils/store/createFetchModule'
import { FetchActionsWith } from '@typings/generic'
import { StatesCount } from '@typings/entities/Report'

export const moduleName = 'reportExpedition'

const fetchModule = createFetchModule('@store/Report/Expedition', 'LOAD', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  setStatusExpeditions: `@store/UI/SET_STATUS_EXPEDITIONS`,
  setSEisLoading: `@store/UI/setSEisLoading`,
}

type ExpeditionReportState = {
  statusExpeditionsIsLoading: boolean
  statusExpeditions: {
    total: StatesCount
    [eshopId: string]: StatesCount
  }
}
const initialState: ExpeditionReportState = {
  statusExpeditions: { total: {} },
  statusExpeditionsIsLoading: false,
}

const ownReducer = (state: ExpeditionReportState = initialState, action: Action): ExpeditionReportState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setStatusExpeditions:
        draft.statusExpeditionsIsLoading = false
        draft.statusExpeditions = action?.payload
        break
      case actionTypes.setSEisLoading:
        draft.statusExpeditionsIsLoading = true
        break
    }
  })
}

export default reduceReducers(fetchModule.reducer, ownReducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  setStatusExpeditions: (count: ExpeditionReportState) => ({ type: actionTypes.setStatusExpeditions, payload: count }),
  setSEisLoading: () => ({ type: actionTypes.setSEisLoading }),
}

const emptyStatesCount = {}

export const selectors = {
  ...fetchModule.selectors,
  statesCount: (state: State, eshop: string | null) =>
    getOr(emptyStatesCount, `statusExpeditions.${eshop || 'total'}`, state?.reportExpedition),
  statesCountIsLoading: (state: State) => getOr(null, `statusExpeditionsIsLoading`, state?.reportExpedition),
  // ... ownSelectors
}
