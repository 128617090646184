import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import stockAdvicesGetQuery from '@queries/stockAdvicesGetQuery'
import stockAdviceItemsGetQuery from '@queries/stockAdviceItemsGetQuery'
import fetchAsyncExport from '@utils/fetchAsyncExport'
import { sagaDebounce } from '@store/helpers'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  // todo this should be refactored so that it does not discard/add other criteria
  if (action.payload?.productId) {
    const stockAdviceItemsCriteria = {
      criteria: {
        product: action.payload?.criteria?.product,
      },
    }
    const stockAdviceItems = await client
      .query({ query: stockAdviceItemsGetQuery, variables: stockAdviceItemsCriteria })
      .then(({ data }) => ({ data: data?.stockAdviceItemsGet }))
    const stockAdvices = stockAdviceItems?.data.results.map((inbound: object) => inbound.id)
    stockAdvices.length && Object.assign(action.payload.criteria, { id: { in: stockAdvices }, status: 'new' })
  }

  return client
    .query({ query: stockAdvicesGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.stockAdvicesGet }))
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.export.run, createFetchSaga(actionTypes.export.run, fetchAsyncExport('stock-advice')))
}
