import produce from 'immer'
import { nanoid } from 'nanoid'
import Message from '@typings/entities/Message'
// TODO move into store/ui to unify with other generic ui elements and add middleware for apolloError to better handle control error visibility

const initialState: Message[] = []

export const actionTypes = {
  apolloError: '%%apollo%%ERROR',
  push: '@StatusRow/PUSH',
  remove: '@StatusRow/REMOVE',
  clear: '@StatusRow/CLEAR',
}

const ownReducer = (state: Message[] = initialState, action: Action): State =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.push:
        draft.push({ id: action?.payload?.id, message: action?.payload?.message, ...action?.payload?.additionalData })
        break
      case actionTypes.remove:
        const index = draft.findIndex(({ id }) => id === action?.payload?.id)
        draft.splice(index, 1)
        break
      case actionTypes.clear:
        return initialState
    }
  })

export const actions = {
  push: (message: string | JSX.Element | any, additionalData?: { [key: string]: any }, delay: number | null = 5000): Action => ({
    type: actionTypes.push,
    payload: {
      id: nanoid(),
      message,
      additionalData,
      delay,
    },
  }),
  remove: (id: string): Action => ({ type: actionTypes.remove, payload: { id } }),
  clear: (): Action => ({ type: actionTypes.clear }),
}

type SelectorDefinitions = { getAll: Message[]; getLast?: Message }

export const selectors: Selectors<SelectorDefinitions> = {
  getAll: (state) => state?.statusRows || [],
  getLast: (state) => state?.statusRows[state?.statusRows?.length - 1],
}

export default ownReducer
