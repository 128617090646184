import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './'
import eshopBaselinkerGet from '@queries/eshopBaselinkerGet'
import { BASELINKER_CORRUPTED_ERROR } from '@typings/entities/Eshop'
import createFetchSaga from '@utils/store/createFetchSaga'

const fetchBaselinkerCredentials = async (client: ApolloClient<any>, action: Action) => {
  if (!action.payload) {
    return
  }

  return client
    .query({
      query: eshopBaselinkerGet,
      variables: { id: action.payload },
      context: {
        suppressError: (err: string, errCode: number): boolean => {
          return errCode == BASELINKER_CORRUPTED_ERROR
        },
      },
    })
    .then(({ data }) => ({
      data: data?.eshopBaselinkerGet,
    }))
    .catch(console.error)
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.setSelectedEshop, createFetchSaga(actionTypes.run, fetchBaselinkerCredentials))
}
