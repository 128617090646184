import getOr from 'lodash/fp/getOr'

declare global {
  interface Window {
    env: {}
  }
}

const configs = typeof window === 'undefined' ? process.env : window.env

const getConfig = (path?: string, defaultValue?: string): any => {
  if (typeof path === 'undefined' || path === null) return configs
  return getOr(defaultValue, path, configs)
}

export default getConfig
