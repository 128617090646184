export type AppearanceType = 'concept' | 'done' | 'error' | 'info' | 'processing' | 'success' | 'warning' | 'waiting'

export const appearanceToColorMap = {
  error: 'red1',
  concept: 'bgLightGray1',
  done: 'darkGreen',
  info: 'lightGray6',
  processing: 'blue2',
  success: 'green',
  warning: 'yellow1',
  waiting: 'gray1',
}

export const appearanceToFontColorMap = {
  error: 'white',
  concept: 'blue2',
  done: 'white',
  info: 'blue2',
  processing: 'white',
  success: 'white',
  warning: 'blue2',
  waiting: 'white',
}
