import reduceReducers from 'reduce-reducers'
import produce from 'immer'
import getOr from 'lodash/fp/getOr'
import createFetchModule from '@utils/store/createFetchModule'
import { ReservationGridClass } from '@typings/entities/Reservation'
import { FetchActionsWith } from '@typings/generic'

export const moduleName = 'reservationsGrid'

const fetchModule = createFetchModule('@store/ReservationsGrid', 'LOAD', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  setReservationClass: `@store/ReservationsGrid/SET_RESERVATION_CLASS`,
}

const ownReducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.setReservationClass:
        draft.reservationClass = action?.payload
        break
    }
  })
}

export default reduceReducers(fetchModule.reducer, ownReducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  setReservationClass: (value: ReservationGridClass) => ({ type: actionTypes.setReservationClass, payload: value }),
}

export const selectors = {
  ...fetchModule.selectors,
  getReservationClass: (state) => getOr<ReservationGridClass>('new', `reservationClass`, state?.reservationsGrid),
}
