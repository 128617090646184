import gql from 'graphql-tag'

const adminGetQuery = gql`
  query adminGet($id: String!) {
    adminGet(id: $id) @rest(type: "Admin", method: "GET", path: "admin/:id") {
      id
      active
      email
      firstName
      lastName
      location
      preferences
    }
  }
`

export default adminGetQuery
