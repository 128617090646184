import produce from 'immer'
import get from 'lodash/fp/get'
import camelCase from 'lodash/camelCase'

// TODO REWORK ERROR HANDLING IN FETCH MODULES AND SUBMODULES

export type FetchModuleState = {
  isLoading: boolean
  loadedAt?: number
  error?: string | null
  errorCode?: string | null
  data: any
  [key: string]: any
}

export type FetchModuleActions = {
  run: (...args: any) => Action
  fetchStarted: (...args: any) => Action
  fetchFailed: (...args: any) => Action
  fetchSucceeded: (...args: any) => Action
}

export type FetchModuleActionTypes = {
  run: string
  fetchStarted: string
  fetchFailed: string
  fetchSucceeded: string
}

type FetchModule<S extends FetchModuleState> = {
  reducer: Reducer<S>
  selectors: Selectors
  actions: FetchModuleActions
  actionTypes: FetchModuleActionTypes
  initialState: FetchModuleState
}

const promiseControlSymbol = Symbol.for('promiseControl')

const createReducer = <S extends FetchModuleState>(actionFullName: string, initialState: S): Reducer<S> => {
  return (state: S = initialState, action: Action): S =>
    produce(state, (draft) => {
      switch (action.type) {
        case `${actionFullName}`:
          draft.isLoading = true
          break
        case `${actionFullName}_FETCH_STARTED`:
          draft.isLoading = true
          draft.loadedAt = undefined
          break
        case `${actionFullName}_FETCH_FAILED`:
          draft.isLoading = false
          draft.error = action.error
          draft.errorCode = action.errorCode || null
          draft.data = null
          break
        case `${actionFullName}_FETCH_SUCCEEDED`:
          draft.isLoading = false
          draft.loadedAt = new Date().getTime()
          draft.error = action.error
          draft.errorCode = action.errorCode || null
          draft.data = action.data
          break
      }
    })
}

const createSelector = (reducerKey: string): Selector => {
  const prefixStartCase = camelCase(reducerKey)
  return (state: State): object => ({
    [`${prefixStartCase}IsLoading`]: get(`[${reducerKey}].isLoading`, state),
    [`${prefixStartCase}Error`]: get(`[${reducerKey}].error`, state),
    [`${prefixStartCase}ErrorCode`]: get(`[${reducerKey}].errorCode`, state),
    [`${prefixStartCase}Data`]: get(`[${reducerKey}].data`, state),
    [`${prefixStartCase}LoadedAt`]: get(`[${reducerKey}].loadedAt`, state),
  })
}

const createSelectors = (reducerKey: string): Selectors => ({ [reducerKey]: createSelector(reducerKey) })

const createActionTypes = (actionFullName: string): FetchModuleActionTypes => {
  return {
    run: actionFullName,
    fetchStarted: `${actionFullName}_FETCH_STARTED`,
    fetchFailed: `${actionFullName}_FETCH_FAILED`,
    fetchSucceeded: `${actionFullName}_FETCH_SUCCEEDED`,
  }
}

const createActions = (actionTypes: FetchModuleActionTypes): FetchModuleActions => {
  return {
    run: (payload: any): Action => {
      let promiseControl = {}
      const promise = new Promise((resolve, reject) => {
        promiseControl = { resolve, reject }
      })
      return {
        type: actionTypes.run,
        payload,
        promise,
        [promiseControlSymbol]: promiseControl,
      }
    },
    fetchStarted: (query: any, data: any): Action => ({
      type: actionTypes.fetchStarted,
      query,
      data,
    }),
    fetchFailed: (query: any, data: any): Action => ({
      type: actionTypes.fetchFailed,
      query,
      data,
    }),
    fetchSucceeded: (query: any, data: any): Action => ({
      type: actionTypes.fetchSucceeded,
      query,
      data,
    }),
  }
}

export default <S extends FetchModuleState>(moduleName: string, actionName: string, reducerKey: string): FetchModule<S> => {
  const actionFullName = `${moduleName}/${actionName}`
  const actionTypes = createActionTypes(actionFullName)
  const initialState: S = {
    isLoading: false,
    error: null,
    errorCode: null,
    data: null,
  }
  return {
    reducer: createReducer(actionFullName, initialState),
    selectors: createSelectors(reducerKey),
    actions: createActions(actionTypes),
    actionTypes,
    initialState,
  }
}
