import { ThemeProvider, ThemeConsumer } from '@xstyled/styled-components'
import React from 'react'
import { Helmet } from 'react-helmet'
import deepmerge from 'deepmerge'
import { Theme } from './types'
import themes from './themes'

type ThemeProps = {
  theme: string
  children: React.ReactNode
}

const Fonts = ({ fontLinks }: { fontLinks?: string[] }): JSX.Element | null => {
  if (!fontLinks || fontLinks.length < 1) return null
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.gstatic.com/"></link>
      {fontLinks.map((fontLink) => (
        <link key={fontLink} href={fontLink} rel="stylesheet"></link>
      ))}
    </Helmet>
  )
}

const DesignSystemThemeProvider = ({ theme, children }: ThemeProps): JSX.Element => {
  const ProviderChildrenFunc = (previousTheme: Theme): JSX.Element => {
    const mergeWith = previousTheme || themes.default
    const currentTheme = themes[theme]
    const mergedTheme = deepmerge(mergeWith, currentTheme)
    return (
      <>
        <Fonts fontLinks={mergedTheme.fontLinks} />
        <ThemeProvider theme={mergedTheme}>{children}</ThemeProvider>
      </>
    )
  }
  return <ThemeConsumer>{ProviderChildrenFunc}</ThemeConsumer>
}

DesignSystemThemeProvider.defaultProps = {
  theme: 'default',
}

export default DesignSystemThemeProvider
