import { cancel, delay, fork, race, take } from 'redux-saga/effects'

export const sagaDebounce = (ms, pattern, task, ...args) =>
  fork(function* () {
    let lastTask
    while (true) {
      let action: any = yield take(pattern)
      if (lastTask) {
        yield cancel(lastTask) // cancel is no-op if the task has already terminated
      }

      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(ms),
          latestAction: take(pattern),
        })

        if (debounced) {
          lastTask = yield fork(task, ...args, action)
          break
        }
        action = latestAction
      }
    }
  })
