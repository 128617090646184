import ApolloClient from 'apollo-client'
import { getContext, call } from 'redux-saga/effects'

// todo this could be do in simpler way, but so far it only copies fetchSubmodule / fetchSaga

const promiseControlSymbol = Symbol.for('promiseControl')

export function getDirectFetchAction(actionName: string) {
  return (payload: any): Action => {
    let promiseControl = {}
    const promise = new Promise((resolve, reject) => {
      promiseControl = { resolve, reject }
    })
    return {
      type: actionName,
      payload,
      promise,
      [promiseControlSymbol]: promiseControl,
    }
  }
}

export function getDirectFetchSaga(fetchList: any) {
  return function* (action: Action): Generator {
    const client: ApolloClient<any> = yield getContext('@apolloClient')
    try {
      const response: object[] = yield call(fetchList, client, action)
      action[promiseControlSymbol].resolve(response)
    } catch (e) {
      action[promiseControlSymbol].reject(e)
    }
  }
}
