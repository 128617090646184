/* eslint-disable react/display-name */
import React from 'react'
import { toast } from 'react-toastify'
import Toast, { ToastType } from '@components/elements/Toast'
import FaIcon from '@mailstep-design-system/icon'

const toastIconMap: { [value: string]: string } = {
  info: 'info',
  success: 'circleCheck',
  error: 'circleX',
  warning: 'circleTriangle',
}

const toastIconFill: { [value: string]: string } = {
  info: 'blue2',
  success: 'darkGreen',
  error: 'red1',
  warning: 'yellow1',
}

export const displayToast = ({ title, text, type }: { title?: string; text?: string; type: ToastType }): void => {
  const icon = toastIconMap[type]
  // eslint-disable-next-line
  toast(<Toast title={title} text={text} type={type} />, {
    type,
    icon: () => <FaIcon icon={icon} size="20px" fill={toastIconFill[type]} />,
    hideProgressBar: true,
    toastId: `${title}${text}`,
  })
}
