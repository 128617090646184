import reduceReducers from 'reduce-reducers'
//import { Actions, Selectors } from '@store/typings'
import { FetchActionsWith } from '@typings/generic'
import createFetchModule from '@utils/store/createFetchModule'
import createFetchSubmodule from '@utils/store/createFetchSubmodule'

export const moduleName = 'expeditions'

const fetchModule = createFetchModule('@store/Expeditions', 'LOAD', moduleName)
const fetchExpedition = createFetchModule('@store/Expeditions', 'LOAD_EXPEDITION', moduleName)
const submoduleCreateExpedition = createFetchSubmodule('@store/Expeditions', 'CREATE', moduleName)
const submoduleEditExpedition = createFetchSubmodule('@store/Expeditions', 'EDIT', moduleName)
const submoduleCancelExpedition = createFetchSubmodule('@store/Expeditions', 'CANCEL', moduleName)
const submoduleRollbackExpedition = createFetchSubmodule('@store/Expeditions', 'ROLLBACK', moduleName)
const sendAllToWmsSubmodule = createFetchSubmodule('@store/ExpeditionsGrid', 'SEND_ALL_TO_WMS', moduleName)
const sendToWmsSubmodule = createFetchSubmodule('@store/Expeditions', 'SEND_TO_WMS', moduleName)
const processToWmsSubmodule = createFetchSubmodule('@store/Expeditions', 'PROCESS_TO_WMS', moduleName)
const downloadImportTemplateSubmodule = createFetchSubmodule('@store/Expeditions', 'DOWNLOAD_IMPORT_TEMPLATE', moduleName)
const downloadInvoiceSubmodule = createFetchSubmodule('@store/Expeditions', 'DOWNLOAD_INVOICE', moduleName)
const importExpeditionsSubmodule = createFetchSubmodule('@store/Expeditions', 'IMPORT_EXPEDITIONS', moduleName)

export const actionTypes = {
  ...fetchModule.actionTypes,
  getExpedition: fetchExpedition.actionTypes,
  createExpedition: submoduleCreateExpedition.actionTypes,
  editExpedition: submoduleEditExpedition.actionTypes,
  cancelExpedition: submoduleCancelExpedition.actionTypes,
  rollbackExpedition: submoduleRollbackExpedition.actionTypes,
  sendAllToWms: sendAllToWmsSubmodule.actionTypes,
  sendToWms: sendToWmsSubmodule.actionTypes,
  processToWms: processToWmsSubmodule.actionTypes,
  downloadImportTemplate: downloadImportTemplateSubmodule.actionTypes,
  downloadInvoice: downloadInvoiceSubmodule.actionTypes,
  importExpeditions: importExpeditionsSubmodule.actionTypes,
}

export default reduceReducers(fetchModule.reducer, fetchExpedition.reducer, sendAllToWmsSubmodule.reducer)

export const actions: FetchActionsWith<typeof actionTypes> = {
  ...fetchModule.actions,
  getExpedition: fetchExpedition.actions.run,
  createExpedition: submoduleCreateExpedition.actions.run,
  editExpedition: submoduleEditExpedition.actions.run,
  cancelExpedition: submoduleCancelExpedition.actions.run,
  rollbackExpedition: submoduleRollbackExpedition.actions.run,
  sendAllToWms: sendAllToWmsSubmodule.actions.run,
  sendToWms: sendToWmsSubmodule.actions.run,
  processToWms: processToWmsSubmodule.actions.run,
  downloadImportTemplate: downloadImportTemplateSubmodule.actions.run,
  downloadInvoice: downloadInvoiceSubmodule.actions.run,
  importExpeditions: importExpeditionsSubmodule.actions.run,
}

export const selectors: Selectors = {
  ...fetchModule.selectors,
}
