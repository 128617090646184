import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import auditsGetQuery from '@queries/auditsGetQuery'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const audits = await client
    .query({ query: auditsGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.auditsGet }))

  audits.data.results = (audits?.data?.results || []).map(({ id, timestamp, content }) => ({
    id,
    timestamp,
    ...content,
  }))

  return audits
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return // dont run on SSR
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
