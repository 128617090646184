import gql from 'graphql-tag'

const complaintsGetQuery = gql`
  query complaintsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    complaintsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, select: $select, nested: true })
      @rest(type: "ComplaintsResult", method: "POST", path: "complaint/list") {
      results
      paging
    }
  }
`

export const complaintsGetCarriersQuery = gql`
  query complaintsGetCarrier($criteria: Object, $select: Array, $nested: boolean) {
    complaintsGetCarries(input: { criteria: $criteria, select: $select, nested: $nested })
      @rest(type: "ComplaintsCarrriers", method: "POST", path: "carrier/list") {
      results
      paging
    }
  }
`

export default complaintsGetQuery
