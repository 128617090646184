import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Acl from './acl'
import AbilityContext from './Context'
import { selectors as adminSelectors } from '@store/admin'
import { selectors as userSelectors } from '@store/user'

type Props = {
  children: React.ReactNode
}

export const Provider = ({ children }: Props): JSX.Element => {
  const [acl] = useState(new Acl())

  const admin = useSelector(adminSelectors.admin)?.adminData
  const user = useSelector(userSelectors.user)?.userData
  const adminAuth = useSelector(adminSelectors.getAuth)
  const userAuth = useSelector(userSelectors.getAuth)
  const userInitComplete = useSelector(userSelectors.getInitComplete)
  const adminInitComplete = useSelector(adminSelectors.getInitComplete)
  const account = admin || user
  const auth = adminAuth || userAuth

  useEffect(() => {
    if (auth?.type == 'user') {
      acl.setUserAbility(auth?.id || null)
    }
    if (auth?.type == 'admin') {
      acl.setAdminAbility(auth?.roles || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acl, auth?.id])

  const accountIsLoading = !!auth?.type && account?.id != auth.id
  const loadingComplete = userInitComplete || adminInitComplete

  useEffect(() => {
    if (loadingComplete && (!auth?.type || !accountIsLoading)) {
      acl.setReady()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountIsLoading, auth?.type, loadingComplete])

  return <AbilityContext.Provider value={acl.getAbility()}>{children}</AbilityContext.Provider>
}
