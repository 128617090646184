import gql from 'graphql-tag'
import { productDetailFields } from './productGetQuery'

const productCreateQuery = gql`
  query productCreate(
    $productSku: String!
    $internalSku: String!
    $eshops: [String]!
    $organisation: String!
    $name: String!
    $weight: Number!
    $height: Number!
    $width: Number!
    $length: Number!
    $referenceNumbers: Array!
    $packagingType: String!
    $productGroup: String
    $workAroundSnIn: Boolean
    $workAroundEanSticker: Boolean
    $imeiCheckRequired: Boolean
    $workAroundWarrantyInf: Boolean
    $workAroundLot: Boolean
    $active: Boolean
    $description: String
    $hsCode: String
    $countryOfOrigin: String
    $description: String
    $type: String
    $childrenProducts: Array
  ) {
    productCreate(
      input: {
        productSku: $productSku
        internalSku: $internalSku
        eshops: $eshops
        organisation: $organisation
        name: $name
        weight: $weight
        height: $height
        width: $width
        length: $length
        referenceNumbers: $referenceNumbers
        packagingType: $packagingType
        productGroup: $productGroup
        workAroundSnIn: $workAroundSnIn
        imeiCheckRequired: $imeiCheckRequired
        workAroundEanSticker: $workAroundEanSticker
        workAroundWarrantyInf: $workAroundWarrantyInf
        workAroundLot: $workAroundLot
        active: $active
        description: $description
        hsCode: $hsCode
        countryOfOrigin: $countryOfOrigin
        description: $description
        type: $type
        childrenProducts: $childrenProducts
      }
    ) @rest(method: "POST", path: "product") {
      ${productDetailFields}
    }
  }
`

export default productCreateQuery
