import { ApolloClient } from '@apollo/client'
import { push } from 'connected-react-router'
import { selectors as routerSelectors } from '@store/connectedRouter'
import { DateTime } from 'luxon'
import { t } from '@lingui/macro'
import { takeLatest, put, call, fork, select, delay, getContext, spawn } from 'redux-saga/effects'
import { actionTypes, actions } from './index'
import { actions as organisationsActions } from '@store/organisations'
import { actions as countriesActions } from '@store/countries'
import { actions as statusRowsActions } from '@store/statusRows'
import { actions as adminsActions } from '@store/admins'
import refreshTokenQuery from '@queries/refreshTokenQuery'
import adminGetQuery from '@queries/adminGetQuery'
import adminUpdateQuery from '@queries/adminUpdateQuery'
import adminUpdateMenuPreferencesQuery from '@queries/adminUpdateMenuPreferencesQuery'
import adminPasswordResetConfirmQuery from '@queries/adminPasswordResetConfirmQuery'
import adminPasswordResetQuery from '@queries/adminPasswordResetQuery'
import organisationActivateQuery from '@queries/organisationActivateQuery'
import organisationCreateQuery from '@queries/organisationCreateQuery'
import organisationDeactivateQuery from '@queries/organisationDeactivateQuery'
import organisationUpdateQuery from '@queries/organisationUpdateQuery'
import loginQuery from '@queries/adminLoginQuery'
import createFetchSaga from '@utils/store/createFetchSaga'
import pages from '@pages/index'
import jwtDecode from 'jwt-decode'
import { displayToast } from '@utils/toast'

const fetchMe = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  // need to use token because storage is not fast enough
  try {
    const currentToken = localStorage?.adminToken
    const decoded = jwtDecode(currentToken)
    const adminId = decoded?.sub
    return client
      .query({
        query: adminGetQuery,
        variables: { id: adminId },
      })
      .then(({ data }) => ({ data: data?.adminGet }))
  } catch (e) {}
}

const fetchUpdateAdmin = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: adminUpdateQuery, variables: { ...action.payload } })
}

const fetchUpdateAdminMenuPreferences = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const currentToken = localStorage?.adminToken
  const decoded = jwtDecode(currentToken)
  const adminId = decoded?.sub
  const { preferences } = action.payload

  if (adminId && preferences) {
    try {
      const res = await client.query({ query: adminUpdateMenuPreferencesQuery, variables: { id: adminId, preferences } })
      displayToast({
        type: 'success',
        text: t({ id: 'form.alertChangesSuccessfullySaved', message: 'Changes have been successfully saved.' }),
      })
      return res
    } catch (error) {
      displayToast({
        type: 'error',
        text: t({ id: 'form.alertChangesNotSaved', message: 'Changes have not been saved.' }),
      })
    }
  }
}

function* loadAdmin(): Generator {
  yield put(actions.load())
  yield put(countriesActions.loadList())
  yield put(organisationsActions.run())
  yield put(adminsActions.run())
}

const fetchLogin = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({
      query: loginQuery,
      variables: action.payload,
      context: {
        suppressError: (err, errCode): boolean => {
          return errCode == 401 || errCode == 502
        },
      },
    })
    .then(({ data }) => ({ data: data?.login }))
}

function* renewToken(): Generator {
  try {
    const client: ApolloClient<any> = yield getContext('@apolloClient')
    const refreshToken = yield call([window.localStorage, window.localStorage.getItem], 'adminRefreshToken')
    const response = yield call(() => client.query({ query: refreshTokenQuery, variables: { refreshToken } }))
    const { token, exp } = response?.data?.newToken
    yield put(actions.setAuth({ token }))
    return true
  } catch (e) {}

  return false
}

function* checkTokenPeriodically(): Generator {
  while (true) {
    yield delay(10000)

    const currentToken: string = yield call([window.localStorage, window.localStorage.getItem], 'adminToken')
    if (currentToken) {
      try {
        const decoded = jwtDecode(currentToken)
        const tokenRefetchTime = DateTime.fromSeconds(decoded?.exp).minus({ seconds: 60 }) // 60s before refreshToken expires
        const currentTime = DateTime.local()

        if (tokenRefetchTime.valueOf() < currentTime.valueOf()) {
          const success = yield call(renewToken)
          if (!success) {
            yield put(
              statusRowsActions.push('Your token has expired.', { default: 'You have been logged out.', intent: 'warning' }),
            )
            yield put(actions.logout())
          }
        }
      } catch (e) {}
    }
  }
}

function* init(): Generator {
  const path: string = yield select(routerSelectors.pathnameSelector)
  if (!/^\/admin/.test(path)) return

  // init from local storage
  const refreshToken = yield call([window.localStorage, window.localStorage.getItem], 'adminRefreshToken')
  if (refreshToken) {
    yield delay(100) // needed prepare apollo client // TODO improve
    const tokenOk = yield call(renewToken)
    if (tokenOk) {
      yield call(loadAdmin)
      if (path == pages.adminLogin.route.toUrl()) yield put(push(pages.adminHome.route.toUrl()))
    } else {
      yield put(actions.logout())
    }
  }

  yield put(actions.setInitComplete())

  yield spawn(checkTokenPeriodically)
}

function* onLoginSuccess(action: Action): Generator {
  yield call([window.localStorage, window.localStorage.setItem], 'adminRefreshToken', action?.data?.refreshToken)
  yield put(actions.setAuth({ token: action?.data?.token }))
  yield call(loadAdmin)
  yield put(push(pages.adminHome.route.toUrl()))
}

function* onSetAuth(action: Action): Generator {
  yield call([window.localStorage, window.localStorage.setItem], 'adminToken', action?.payload?.token)
}

function* onLogout(): Generator {
  yield call([window.localStorage, window.localStorage.removeItem], 'adminToken')
  yield call([window.localStorage, window.localStorage.removeItem], 'adminRefreshToken')
  const path: string = yield select(routerSelectors.pathnameSelector)
  if (!/^\/admin\/login/.test(path)) yield put(push(pages.adminLogin.route.toUrl()))
}

const fetchPasswordResetQuery = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: adminPasswordResetQuery, variables: action.payload })
}

const fetchPasswordResetConfirmQuery = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: adminPasswordResetConfirmQuery, variables: action.payload })
}

// TODO!! - organisation actions should be in organistaion/saga At least becasue users use them too

const fetchUpdateOrganisation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: organisationUpdateQuery, variables: action.payload })
}

const fetchCreateOrganisation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: organisationCreateQuery, variables: action.payload })
}

const fetchActivateOrganisation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: organisationActivateQuery, variables: action.payload }).then(() => {
    window.location.reload()
  })
}

const fetchDeactivateOrganisation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: organisationDeactivateQuery, variables: action.payload }).then(() => {
    window.location.reload()
  })
}

function* fetchOrgChangeSuccess(action: Action): Generator {
  yield put(organisationsActions.run())
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return // dont run on SSR
  yield fork(init) // this saga is called one time on init
  yield takeLatest(actionTypes.load.run, createFetchSaga(actionTypes.load.run, fetchMe))
  yield takeLatest(actionTypes.login.run, createFetchSaga(actionTypes.login.run, fetchLogin))
  yield takeLatest(actionTypes.login.fetchSucceeded, onLoginSuccess)
  yield takeLatest(actionTypes.logout, onLogout)
  yield takeLatest(actionTypes.setAuth, onSetAuth)
  yield takeLatest(actionTypes.update.run, createFetchSaga(actionTypes.update.run, fetchUpdateAdmin))
  yield takeLatest(
    actionTypes.updateMenuPreferences.run,
    createFetchSaga(actionTypes.updateMenuPreferences.run, fetchUpdateAdminMenuPreferences),
  )
  yield takeLatest(actionTypes.editOrganisation.run, createFetchSaga(actionTypes.editOrganisation.run, fetchUpdateOrganisation))
  yield takeLatest(actionTypes.editOrganisation.fetchSucceeded, fetchOrgChangeSuccess)
  yield takeLatest(
    actionTypes.createOrganisation.run,
    createFetchSaga(actionTypes.createOrganisation.run, fetchCreateOrganisation),
  )
  yield takeLatest(actionTypes.createOrganisation.fetchSucceeded, fetchOrgChangeSuccess)
  yield takeLatest(
    actionTypes.activateOrganisation.run,
    createFetchSaga(actionTypes.activateOrganisation.run, fetchActivateOrganisation),
  )
  yield takeLatest(
    actionTypes.deactivateOrganisation.run,
    createFetchSaga(actionTypes.deactivateOrganisation.run, fetchDeactivateOrganisation),
  )
  yield takeLatest(actionTypes.passwordReset.run, createFetchSaga(actionTypes.passwordReset.run, fetchPasswordResetQuery))
  yield takeLatest(
    actionTypes.passwordResetConfirm.run,
    createFetchSaga(actionTypes.passwordResetConfirm.run, fetchPasswordResetConfirmQuery),
  )
}
