import gql from 'graphql-tag'

const expeditionInvoiceDeleteQuery = gql`
  query expeditionInvoiceDelete($id: Int!) {
    expeditionInvoiceDelete(id: $id)
      @rest(type: "ExpeditionInvoiceDeleteResult", method: "DELETE", path: "expedition/:id/invoice") {
      result
    }
  }
`

export default expeditionInvoiceDeleteQuery
