import { ApolloClient } from '@apollo/client'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import productsGetQuery from '@queries/productsGetQuery'
import { ProductApiRead } from '@typings/entities/Product'
import { sagaDebounce } from '@store/helpers'

const listFields = [
  'active',
  'createdAt',
  'description',
  'eshops',
  'height',
  'id',
  'images',
  'internalSku',
  'name',
  'organisation',
  'packagingType',
  'productGroup',
  'productSku',
  'referenceNumbers',
  'weight',
  'width',
  'length',
  'workAroundEanSticker',
  'workAroundLot',
  'imeiCheckRequired',
  'workAroundSnIn',
  'workAroundWarrantyInf',
  'booked',
  'inStock',
  'quantityToStock',
  'type',
]

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const products: { data: { results: ProductApiRead[] } } = await client
    .query({ query: productsGetQuery, variables: { select: listFields, ...action.payload, nested: true } })
    .then(({ data }) => ({ data: data?.productsGet }))

  products.data.results = products?.data?.results || []

  return products
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
