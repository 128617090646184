import gql from 'graphql-tag'

const eshopDeleteQuery = gql`
  query eshopDelete($eshopId: Int!) {
    eshopDelete(id: $eshopId) @rest(type: "EshopDeleteResult", method: "DELETE", path: "eshop/:id") {
      result
    }
  }
`

export default eshopDeleteQuery
