import { ApolloClient } from '@apollo/client'
import { put, delay, call } from 'redux-saga/effects'
import exportQuery from '@queries/exportQuery'
import exportJobQuery from '@queries/exportJobQuery'
import { actions as uiActions } from '@store/ui'
import objectToURLParams from '@utils/objectToURLParams'

const fetchAsyncExport = (entity: string) =>
  function* fetchAsyncExport(client: ApolloClient<any>, action: Action): Generator {
    try {
      yield put(uiActions.pushExportDialog({ isLoading: true }))
      const { queryProps, ...payload } = action.payload
      const { data }: any = yield call(() =>
        client.query({ query: exportQuery, variables: { ...payload, entity, queryProps: objectToURLParams(queryProps) } }),
      )
      const jobID = data?.exportResult?.id
      const fileName = `${data?.exportResult?.filename}.${data?.exportResult?.fileType}`
      let exportStatus, fileUrl

      while (exportStatus !== 'finished' && exportStatus !== 'failed') {
        yield delay(500)
        const { data }: any = yield call(() => client.query({ query: exportJobQuery, variables: { jobID } }))
        exportStatus = data?.exportJobResult?.status
        fileUrl = data?.exportJobResult?.fileUrl
        if (exportStatus !== 'finished') {
          const progress = Math.floor((100 * data?.exportJobResult?.processedRecords) / data?.exportJobResult?.expectedRecords)
          yield put(uiActions.pushExportDialog({ isLoading: true, progress: progress || 0 }))
        }
      }

      if (!fileUrl) {
        throw new Error("export doesn't contain fileUrl")
      }

      if (exportStatus === 'failed') {
        throw new Error('export ended with status failed')
      }

      yield put(uiActions.pushExportDialog({ isLoading: false, fileUrl, fileName }))
    } catch (error) {
      yield put(uiActions.pushExportDialog({ isLoading: false, error: true }))
    }
  }

export default fetchAsyncExport
