/* eslint-disable react/display-name */
import React from 'react'
import styled from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import { Trans } from '@lingui/react'
import { ToastOptions } from 'react-toastify'
import Icon from '@components/elements/Icon'

export type ToastType = 'info' | 'success' | 'error' | 'warning'

type CustomToastProps = ToastOptions & {
  closeToast?: void
  toastProps?: ToastOptions
  title?: string
  text?: string
  type: ToastType
}

const toastTitleMap: { [value: string]: React.ReactElement } = {
  info: <Trans id="cornerDialog.info.title" message="Info" />,
  success: <Trans id="cornerDialog.success.title" message="Success" />,
  error: <Trans id="cornerDialog.failed.title" message="Error" />,
  warning: <Trans id="cornerDialog.warning.title" message="Warning" />,
}

const Title = styled.span`
  display: block;
  margin-bottom: 5px;
  line-height: 18px;
  font-weight: 700;
  color: ${th('colors.blue2')};
`

const Text = styled.span`
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: ${th('colors.gray1')};
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const CloseWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: 15px;

  svg {
    cursor: pointer;
  }
`

const Toast = ({ closeToast, title, text, type }: CustomToastProps): JSX.Element => {
  return (
    <ContentWrapper>
      <div>
        <Title>{title || toastTitleMap[type]}</Title>
        {text && <Text>{text}</Text>}
      </div>
      {!!closeToast && (
        <CloseWrapper>
          <div onClick={closeToast}>
            <Icon icon="close" width="14px" height="14px" />
          </div>
        </CloseWrapper>
      )}
    </ContentWrapper>
  )
}

export default Toast
