import loadable, { LoadableComponent } from '@loadable/component'
import React, { memo } from 'react'
import { useTheme } from '@xstyled/styled-components'
import { th } from '@xstyled/system'

type IconProps = {
  width?: number | string
  height?: number | string
}

type Props = IconProps & {
  icon: string
  fill?: string
  stroke?: string
}

const formatName = (s: string): string => {
  if (typeof s !== 'string') {
    return ''
  }
  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`
}

const Icon = ({ icon, fill, ...props }: Props): JSX.Element => {
  const theme = useTheme()
  const colorFill = fill && th.color(fill)({ theme })

  const iconFormatted = formatName(icon)
  const IconComponent: LoadableComponent<IconProps> = loadable(() => import(`./icons/${iconFormatted}`))
  return <IconComponent fill={colorFill} {...props} />
}

export default memo(Icon)
