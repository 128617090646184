import { takeLatest, select, delay, put, call, fork, getContext } from 'redux-saga/effects'
import ApolloClient from 'apollo-client'
import { selectors as uiSelectors, actions as uiActions, actionTypes as uiActionTypes } from './index'
import { selectors as organisationSelectors, actionTypes as organisationActionTypes } from '../organisation/index'
import { selectors as userSelectors } from '@store/user'
import { actions as statusRowsActions } from '@store/statusRows'
import { actionTypes as expeditionActionsTypes } from '@store/expeditions'
import reportExpeditionGetQuery from '@queries/reportExpeditionGetQuery'
import notificationsUnseenCountGet from '@queries/notificationUnseenCountGet'
import complaintsGetQuery from '@queries/complaintsGetQuery'
import reportReservationsPartiallyDelivered from '@queries/reportReservationsPartiallyDelivered'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import { selectors as adminSelectors } from '@store/admin'
import { t } from '@lingui/macro'

async function loadNotificationsAndWarnings({ isUserLoggedIn, accountId, organisation, client }): Promise<any> {
  // count of expedition that are in incorrect state
  const expeditionsPromise = client.query({
    query: reportExpeditionGetQuery,
    variables: {
      select: ['count'],
      criteria: {
        status: { eq: 'incorrect' },
        organisation: { eq: organisation },
      },
    },
  })

  // count of reservations on partially expedited expeditions
  const reservationsPromise = client.query({
    query: reportReservationsPartiallyDelivered,
    variables: {
      organisation: { eq: organisation },
    },
  })

  // count of unseen notifications
  const notificationsPromise =
    getRuntimeConfig('SHOW_NOTIFICATIONS') && isUserLoggedIn
      ? client.query({
          query: notificationsUnseenCountGet,
          variables: {
            id: organisation,
          },
        })
      : Promise.resolve(0)

  const complaintsPromiseReporter = client.query({
    query: complaintsGetQuery,
    variables: {
      criteria: {
        status: { eq: 'waiting_for_reporter' },
        reporter: accountId,
        organisation: { eq: organisation },
      },
      limit: 1,
    },
  })

  const complaintsPromiseResolver = client.query({
    query: complaintsGetQuery,
    variables: {
      criteria: {
        status: { eq: 'waiting_for_resolver' },
        resolver: accountId,
        organisation: { eq: organisation },
      },
      limit: 1,
    },
  })

  const [
    expeditionsResponse,
    reservationsResponse,
    notificationsResponse,
    complaintsReporterResponse,
    complaintsResolverResponse,
  ] = await Promise.all([
    expeditionsPromise,
    reservationsPromise,
    notificationsPromise,
    complaintsPromiseReporter,
    complaintsPromiseResolver,
  ])

  const reservationsResults = reservationsResponse?.data?.reportReservationGet
  const reservationCount = reservationsResults?.count || 0

  const expeditionsResults = expeditionsResponse?.data?.reportExpeditionGet?.results
  const expeditionsCount = expeditionsResults?.[0]?.count || 0

  const waitingForReporterCount = complaintsReporterResponse?.data?.complaintsGet?.paging?.total || 0
  const waitingForResolverCount = complaintsResolverResponse?.data?.complaintsGet?.paging?.total || 0
  const warningsCount = expeditionsCount + waitingForReporterCount + waitingForResolverCount
  const notificationsCount = notificationsResponse?.data?.notificationsUnseenCountGet || 0
  const totalNotificationsCount = warningsCount + notificationsCount

  return {
    reservationCount,
    expeditionsCount,
    waitingForReporterCount,
    waitingForResolverCount,
    warningsCount,
    notificationsCount,
    totalNotificationsCount,
  }
}

function* loadUiBadges(): Generator {
  yield put(uiActions.setIncorrectExpeditions(null))
  yield put(uiActions.setReservationsOnExpedited(null))
  yield put(uiActions.setUnseenNotifications(null))

  const organisation: string = yield select(organisationSelectors.organisation)
  const userData: any = yield select(userSelectors.user)
  const isUserLoggedIn = !!userData?.userData?.id
  const accountId = yield select((state) => adminSelectors.getAuth(state)?.id || userSelectors.getAuth(state)?.id) // using auth to prevent race condition - TODO refactor
  const client: ApolloClient<any> = yield getContext('@apolloClient')

  if (!organisation) return

  try {
    const {
      expeditionsCount,
      reservationCount,
      notificationsCount,
      waitingForReporterCount,
      waitingForResolverCount,
      warningsCount,
      totalNotificationsCount,
    }: any = yield call(() => loadNotificationsAndWarnings({ isUserLoggedIn, accountId, organisation, client }))

    yield put(uiActions.setIncorrectExpeditions(expeditionsCount))
    yield put(uiActions.setReservationsOnExpedited(reservationCount))

    if (getRuntimeConfig('SHOW_NOTIFICATIONS')) {
      yield put(uiActions.setUnseenNotifications(notificationsCount))
    }

    yield put(
      uiActions.setComplaintWarnings({
        waitingForReporter: waitingForReporterCount,
        waitingForResolver: waitingForResolverCount,
      }),
    )

    yield put(uiActions.setWarningsCount(warningsCount))
    yield put(uiActions.setNotificationsTotalCount(totalNotificationsCount))
  } catch (err) {
    console.log(err)
  }
}

async function getVersion(): Promise<string | void> {
  try {
    const promise: Promise<any> = fetch('/app-manifest.json')
    const response = await promise
    const data = await response.json()
    return data.appVersion
  } catch (e) {
    console.log('Error while checking version', e)
  }
}

function* checkVersionPeriodically(): Generator {
  const checkPeriod = 15 * 60 * 1000 // 15 mins
  const currentAppVersion: string = getRuntimeConfig('APP_VERSION')
  if (!currentAppVersion) return // no current version available, nothing to compare
  let lastRemoteAppVersion
  let shouldCheck = true
  while (shouldCheck) {
    yield delay(checkPeriod)
    const remoteAppVersion = yield call(getVersion)
    if (remoteAppVersion === lastRemoteAppVersion) {
      // Last two versions are equal
      if (remoteAppVersion !== currentAppVersion) {
        shouldCheck = false
        console.log(`You should update, your version is ${currentAppVersion}, remote is ${remoteAppVersion}`)
        yield put(
          statusRowsActions.push(
            t({
              id: 'status.newVersion.title',
              message: 'New version available.',
            }),
            {
              default: t({
                id: 'status.newVersion.text',
                message: 'You can update to the latest version.',
              }),
              intent: 'info',
              onClick: () => window.location.reload(),
            },
            null,
          ),
        )
      }
    }
    lastRemoteAppVersion = remoteAppVersion
  }
}

function* pushDialog(action: Action): Generator {
  const cornerDialog = yield select((state) => uiSelectors.default(state, 'cornerDialog'))
  if (cornerDialog) {
    if (!action?.payload?.notimeout) {
      yield delay(6000)
      yield put(uiActions.pushDialog(null))
    }
  }
}

function* updateUiPeriodically(): Generator {
  let client: ApolloClient<any> | undefined = undefined

  while (true) {
    yield delay(10000)

    if (!getRuntimeConfig('SHOW_NOTIFICATIONS')) continue
    if (!client) client = (yield getContext('@apolloClient')) as ApolloClient<any>
    if (!client) continue // hack: initialization of apolloClient is under some race condition - skip and try again later if it is not ready yet

    try {
      const organisation: string = yield select(organisationSelectors.organisation)
      const userData: any = yield select(userSelectors.user)
      const isUserLoggedIn = !!userData?.userData?.id
      if (organisation && isUserLoggedIn) {
        const notificationsResponse = yield call(() =>
          client.query({
            query: notificationsUnseenCountGet,
            variables: {
              id: organisation,
            },
          }),
        )

        const notificationsCount = notificationsResponse?.data?.notificationsUnseenCountGet || 0
        yield put(uiActions.setUnseenNotifications(notificationsCount))

        const warningsCount = yield select((state) => uiSelectors.warningsCount(state))
        yield put(uiActions.setNotificationsTotalCount(notificationsCount + warningsCount))
      }
    } catch (e) {
      yield put(uiActions.setUnseenNotifications(0))
    }
  }
}

export default function* watch(): Generator {
  if (typeof window !== 'undefined') {
    yield fork(checkVersionPeriodically) // this saga is called one time on init
    yield fork(updateUiPeriodically)
  }
  yield takeLatest(organisationActionTypes.setOrganisation, loadUiBadges)
  yield takeLatest(expeditionActionsTypes.editExpedition.fetchSucceeded, loadUiBadges)
  yield takeLatest(uiActionTypes.pushDialog, pushDialog)
}
