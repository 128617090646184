import get from 'lodash/fp/get'
import { takeLatest, select, put } from 'redux-saga/effects'
import { selectors as organisationsSelectors } from '@store/organisations'
import {
  selectors as organisationSelectors,
  actions as organisationActions,
  actionTypes as organisationActionTypes,
} from './index'
import { actionTypes as organisationsActionTypes } from '@store/organisations'
import { actions as eshopsActions } from '@store/eshops'
import { actions as commonGridExtActions } from '@store/commonGridExt'
import { OrganisationApi } from '@typings/entities/Organisation'

function* onOrganisationsLoaded(action: Action): Generator {
  const selectedOrganisation = yield select((state) => organisationSelectors.organisation(state))
  const orgFound = get('data', action).find((organisation: OrganisationApi) => selectedOrganisation == organisation.id)

  if ((!selectedOrganisation && selectedOrganisation !== '') || (selectedOrganisation && !orgFound)) {
    const firstOrganisation: OrganisationApi | undefined = get('[0]', action?.data)
    if (firstOrganisation) {
      yield put(organisationActions.setOrganisation(firstOrganisation.id))
    }
  } else {
    // triger fetch of eshops by selected organisation
    yield put(organisationActions.setOrganisation(selectedOrganisation, true))
  }
}

function* onOrganisationSet(action: Action): Generator {
  if (!action?.options.initOnly) {
    yield put(commonGridExtActions.clearAllFilers())
  }
  const selectedId: string = action?.payload
  if (selectedId) {
    yield put(
      eshopsActions.run({
        criteria: { organisation: { eq: selectedId } },
      }),
    )
    return
  }
  const data: { organisationsData: OrganisationApi[] } = yield select(organisationsSelectors.organisations)
  const allOrganisationsId: string[] = (data?.organisationsData || []).map(({ id }) => id)
  yield put(
    eshopsActions.run({
      criteria: { organisation: { in: allOrganisationsId } },
    }),
  )
}

export default function* watch(): Generator {
  yield takeLatest(organisationsActionTypes.fetchSucceeded, onOrganisationsLoaded)
  yield takeLatest(organisationActionTypes.setOrganisation, onOrganisationSet)
}
