import gql from 'graphql-tag'

export const productDetailFields = `
  active
  createdAt
  eshops
  organisation
  height
  intHeight
  id
  images
  intImages
  internalSku
  length
  intLength
  name
  packagingType
  productSku
  productGroup
  referenceNumbers
  weight
  intWeight
  width
  intWidth
  workAroundEanSticker
  workAroundLot
  imeiCheckRequired
  workAroundSnIn
  workAroundWarrantyInf
  hsCode
  countryOfOrigin
  description
  type
  childrenProducts
`

const productGetQuery = gql`
  query productGet($id: Int!) {
    productGet(id: $id) @rest(type: "ProductResult", method: "GET", path: "product/{args.id}") {
      ${productDetailFields}
    }
  }
`

export default productGetQuery
