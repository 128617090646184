import { th, system } from '@xstyled/system'
import styled, { css, FlattenSimpleInterpolation } from '@xstyled/styled-components'
import FaIcon from '@mailstep-design-system/icon'

export type SizingValue = 'icon' | 'iconLg' | 'normal' | 'large' | 'grid'
export type AppearanceValue = 'primary' | 'secondary' | 'primaryLg' | 'secondaryLg' | 'minimal' | 'minimalRed'

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledWrapper = styled.span`
  // -------- size variant ----------
  .icon {
    aspect-ratio: 4 / 3;
    padding: 0.5em 0.875em;
  }
  .iconLg {
    min-width: 42px;
    min-height: 38px;
    padding: 0.5em 0.875em;
    svg {
      height: 16px;
    }
  }
  .normal {
    min-width: 7rem;
    padding: 12px 18px;
    min-height: 38px;
    line-height: 14px;
    font-size: 14px;
  }
  .large {
    min-width: 12rem;
    padding: 0.75em 1.5em;
    min-height: 34px;
    font-size: 12px;
  }
  .grid {
    min-width: 5rem;
    padding: 0.5em 1.25em;
    font-size: 10px;
    border-radius: 5px;
  }

  // ---------- appearance variant -------
  .secondary {
    background-color: white;
    color: red1;
    border-color: red1;

    :hover {
      background-color: red1;
      color: white;
      border-color: red1;
    }

    :active {
      background-color: red1;
      color: red1;
      border-color: red1;
    }

    :disabled {
      background-color: white;
      border-color: lightGray7;
    }
  }

  .primary {
    background-color: red1;
    color: white;
    border-color: red1;

    :hover {
      background-color: white;
      color: red1;
      border-color: red1;
    }

    :active {
      background-color: red1;
      color: red1;
      border: none;
    }

    :disabled {
      background-color: bgLightGray1;
      border-color: bgLightGray1;
    }
  }
  .secondaryLg {
    background-color: white;
    color: typoPrimary;
    border-color: blue2;

    :hover {
      background-color: blue2;
      color: white;
      border-color: blue2;
    }

    :active {
      background-color: gray;
      color: white;
      border: none;
    }

    :disabled {
      background-color: white;
      border-color: bgLightGray1;
    }
  }
  .primaryLg {
    background-color: blue2;
    color: white;
    border-color: blue2;

    :hover {
      background-color: white;
      color: typoPrimary;
      border-color: blue2;
    }

    :active {
      background-color: gray;
      color: white;
      border: none;
    }

    :disabled {
      background-color: bgLightGray1;
      border-color: bgLightGray1;
    }
  }
  .minimal {
    background-color: transparent;
    border: none !important;
    color: blue2;

    :disabled {
      color: blue2;
    }
  }
  .minimalRed {
    background-color: transparent;
    border: none !important;
    color: red1;

    :disabled,
    :hover {
      color: red3;

      svg {
        fill: red3 !important;
      }
    }
  }
`

export const LoadingIconWrapper = styled.div<{
  $addMargin: boolean
}>`
  & svg {
    margin-right: ${({ $addMargin }): string => ($addMargin ? '0.5em' : '0')};
  }
`

export const StyledIcon = styled(FaIcon)<{
  $addMargin: boolean
}>`
  & svg {
    height: 1em;
    margin-right: ${({ $addMargin }): string => ($addMargin ? '0.5em' : '0')};
  }
`

export const StyledButton = styled.button<{
  isLoading?: boolean
  disabled: boolean
  columnAlign?: boolean
}>`
  ${system};
  width: initial;
  height: 2.5em;

  :focus {
    outline: none;
  }

  border: slim;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  font-family: ${th('fonts.primary')};
  font-weight: semiBold;
  border-radius: lg;
  cursor: pointer;
  transition-duration: 0.1s;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease-out;

  :disabled {
    color: lightGray5;
  }

  ${({ disabled }): FlattenSimpleInterpolation | null =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.6;
        `
      : null}
  ${({ columnAlign }): FlattenSimpleInterpolation | null =>
    columnAlign
      ? css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `
      : null}
`
