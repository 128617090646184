import styled from '@xstyled/styled-components'
import React, { useCallback } from 'react'
import { Trans } from '@lingui/react'
import FaIcon from '@mailstep-design-system/icon'
import Button from '@components/elements/Button'
import { RouteChildrenProps } from 'react-router'
import routes from '@pages/index'

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  margin-top: 150px;
`

const Container = styled.div`
  text-align: center;
  & > img {
    height: 400px;
  }
`

const Header = styled.div`
  font-weight: bold;
  font-size: 80px;
  color: typoPrimary;
  font-weight: 700;
  margin-top: 30px;
`

const FaceIcon = styled(FaIcon)`
  color: lightGray3;
  opacity: 0.5;
  font-size: 120px;
`

const PageNotFound = styled.div`
  color: typoPrimary;
  font-size: 35px;
  font-weight: SemiBold;
  font-weight: 600;
`

const StyledButton = styled(Button)`
  margin-top: 50px;
`

type Props = RouteChildrenProps

const Error404Page = ({ history, location }: Props): JSX.Element => {
  const goToHome = useCallback(() => {
    if (location.pathname.match(/^\/admin\//)) {
      history.push(routes.adminHome.route.toUrl())
    } else {
      history.push(routes.userHome.route.toUrl())
    }
  }, [history, location])

  return (
    <Wrapper>
      <Container>
        <FaceIcon icon="faceMeh" />
        <Header>
          <Trans id="404.error" message="404 Error" />
        </Header>
        <PageNotFound>
          <FaIcon icon="handStop" />
          <Trans id="404.pageNotFound" message="Page not found" />
        </PageNotFound>
        <StyledButton appearance="primary" type="button" onClick={goToHome}>
          <FaIcon icon="home" />
          &nbsp;
          <Trans id="404.buttonDashboard" message="Dashboard" />
        </StyledButton>
      </Container>
    </Wrapper>
  )
}

export default Error404Page
