import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import stockAdviceItemsGetQuery from '@queries/stockAdviceItemsGetQuery'
import stockAdvicesGetQuery from '@queries/stockAdvicesGetQuery'
import { asNestedFields } from '@typings/entities/StockAdvice'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const { deepNested, ...variables } = action.payload

  const stockAdviceItems = await client
    .query({ query: stockAdviceItemsGetQuery, variables })
    .then(({ data }) => ({ data: data?.stockAdviceItemsGet }))

  if (deepNested && stockAdviceItems.data.results.length) {
    const stockAdvicesIds = stockAdviceItems.data.results.map((item) => item.stockAdvice)
    const stockAdvices = await client
      .query({
        query: stockAdvicesGetQuery,
        variables: {
          criteria: { id: { in: stockAdvicesIds } },
          select: [...asNestedFields, 'supplier'],
        },
      })
      .then(({ data }) => data?.stockAdvicesGet.results)
    stockAdviceItems.data.results = stockAdviceItems.data.results.map((item) => ({
      ...item,
      stockAdvice: stockAdvices.find((stockAdvice) => item.stockAdvice == stockAdvice.id),
    }))
  }

  return stockAdviceItems
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
