import { AxiosInstance } from 'axios'
import {
  OrderSyncAggregatedData,
  OrderSyncDetailData,
  OrderSynchronization,
  OrderSynchronizationDetail,
  OrderSyncRetryData,
} from '@typings/entities/OrderSynchronization'
import { WithData } from '@typings/generic'
import { getBaselinkerRoutes } from '@services/BaselinkerApi/baselinkerRoutes'

export const SyncOrderApi = (instance: AxiosInstance) => ({
  async orderSyncAggregated(data: OrderSyncAggregatedData) {
    return instance.post<OrderSyncAggregatedData, WithData<OrderSynchronization[]>>(getBaselinkerRoutes().order.aggregated, data)
  },
  async orderSyncDetail(data: OrderSyncDetailData) {
    return instance.post<OrderSyncDetailData, WithData<OrderSynchronizationDetail[]>>(getBaselinkerRoutes().order.details, data)
  },
  async orderSyncRetry(data: OrderSyncRetryData) {
    return instance.post<OrderSyncRetryData>(getBaselinkerRoutes().order.retry, data)
  },
})
