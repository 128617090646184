import React, { RefObject } from 'react'
import {
  StyledButton,
  LoadingIconWrapper,
  StyledIcon,
  StyledWrapper,
  SizingValue,
  AppearanceValue,
  ContentWrapper,
} from './styles'
import { Spinner } from '@components/elements/Spinner'

export type Props = React.HTMLProps<HTMLButtonElement> & {
  children?: React.ReactNode
  disabled?: boolean
  appearance?: AppearanceValue
  buttonRef?: (ref: RefObject<HTMLElement>) => void
  sizing?: SizingValue
  isLoading?: boolean
  name?: string
  as?: string | React.ComponentType
  loadingText?: string | JSX.Element
  columnAlign?: boolean
  icon?: string | JSX.Element
}

const Button = ({
  isLoading,
  loadingText,
  icon,
  appearance = 'primary',
  disabled = false,
  sizing = 'normal',
  buttonRef,
  ...props
}: Props): JSX.Element => {
  const isLoadingTextEmpty = loadingText === ''

  if (isLoading) {
    return (
      <StyledWrapper>
        <StyledButton {...props} className={`${appearance} ${sizing}`} disabled={true} isLoading={true} ref={buttonRef}>
          <LoadingIconWrapper $addMargin={!isLoadingTextEmpty}>
            <Spinner variant="sm" />
          </LoadingIconWrapper>
          {loadingText || isLoadingTextEmpty ? loadingText : 'Loading'}
        </StyledButton>
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper>
      <StyledButton {...props} className={`${appearance} ${sizing}`} disabled={disabled}>
        <ContentWrapper>
          {icon && (typeof icon === 'string' ? <StyledIcon $addMargin={!!props.children} icon={icon} /> : icon)}
          {props.children}
        </ContentWrapper>
      </StyledButton>
    </StyledWrapper>
  )
}

export default Button
