import React from 'react'
import BaseText, { TextProps as BaseTextProps } from '../Text'

export const Text = (props: BaseTextProps): JSX.Element => <BaseText {...props} fontWeight={props.variant} />
Text.defaultProps = { fontFamily: 'primary' }

// Paragraphs
export const Paragraph1 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
Paragraph1.defaultProps = { as: 'p', fontFamily: 'primary', fontSize: '18px' }

export const Paragraph2 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
Paragraph2.defaultProps = { as: 'p', fontFamily: 'primary', fontSize: '16px' }

export const Paragraph3 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
Paragraph3.defaultProps = { as: 'p', fontFamily: 'primary', fontSize: '14px' }

export const Paragraph4 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
Paragraph4.defaultProps = { as: 'p', fontFamily: 'primary', fontSize: '12px' }

export const Paragraph5 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
Paragraph5.defaultProps = { as: 'p', fontFamily: 'primary', fontSize: '10px' }

// Headings
export const H1 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
H1.defaultProps = { as: 'h1', fontFamily: 'heading', fontSize: '32px', mt: 2, mb: 2 }

export const H2 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
H2.defaultProps = { as: 'h2', fontFamily: 'heading', fontSize: '24px', mt: 2, mb: 2 }

export const H3 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
H3.defaultProps = { as: 'h3', fontFamily: 'heading', fontSize: '21px', mt: 2, mb: 2 }

export const H4 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
H4.defaultProps = { as: 'h4', fontFamily: 'heading', fontSize: '18px', mt: 2, mb: 2 }

export const H5 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
H5.defaultProps = { as: 'h5', fontFamily: 'heading', fontSize: '16px', mt: 2, mb: 2 }

export const H6 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
H6.defaultProps = { as: 'h6', fontFamily: 'heading', fontSize: '14px', mt: 2, mb: 2 }

export const H7 = (props: BaseTextProps): JSX.Element => <Text {...props} fontWeight={props.variant} />
H7.defaultProps = { as: 'h6', fontFamily: 'heading', fontSize: '12px', mt: 2, mb: 2 }
