import { all } from 'redux-saga/effects'
import commonFetchSaga from '@utils/store/commonFetchSaga'
import { Store } from 'redux'

import admin from './admin/sagas'
import admins from './admins/sagas'
import auditsGrid from './auditsGrid/sagas'
import carriers from './carriers/sagas'
import carriersGrid from './carriersGrid/sagas'
import complaintsGrid from './complaintsGrid/sagas'
import countries from './countries/sagas'
import dashboardData from './dashboard/sagas'
import eshops from './eshops/sagas'
import baselinkerCredentials from '@store/baselinkerCredentials/sagas'
import baselinkerBatch from '@store/baselinkerBatch/sagas'
import eshopsGrid from './eshopsGrid/sagas'
import expeditionItemsGrid from './expeditionItemsGrid/sagas'
import expeditions from './expeditions/sagas'
import expeditionsGrid from './expeditionsGrid/sagas'
import filters from './filters/sagas'
import inboundReceiptsGrid from './inboundReceiptsGrid/sagas'
import internalStockChangesGrid from './internalStockChangesGrid/sagas'
import notificationSettingsGrid from './notificationSettingsGrid/sagas'
import notificationsGrid from './notificationsGrid/sagas'
import missingStockGrid from './missingStockGrid/sagas'
import ordersSynchronizationGrid from './ordersSynchronizationGrid/sagas'
import orderSynchronizationDetailGrid from './orderSynchronizationDetailGrid/sagas'
import organisations from './organisations/sagas'
import organisation from './organisation/sagas'
import organisationsGrid from './organisationsGrid/sagas'
import outboundReceiptsGrid from './outboundReceiptsGrid/sagas'
import partnersGrid from './partnersGrid/sagas'
import productStocks from './productStocks/sagas'
import productStocksGrid from './productStocksGrid/sagas'
import products from './products/sagas'
import productsGrid from './productsGrid/sagas'
import productsSynchronizationGrid from './productsSynchronizationGrid/sagas'
import unsyncedProductsGrid from './unsyncedProductsGrid/sagas'
import productSynchronizationDetailGrid from './productSynchronizationDetailGrid/sagas'
import reportExpedition from './report/expedition/sagas'
import reservationsGrid from './reservationsGrid/sagas'
import returnReceiptsGrid from './returnReceiptsGrid/sagas'
import statusRows from './statusRows/sagas'
import stockAdviceItemsGrid from './stockAdviceItemsGrid/sagas'
import stockAdvices from './stockAdvices/sagas'
import stockAdvicesGrid from './stockAdvicesGrid/sagas'
import stockChangesGrid from './stockChangesGrid/sagas'
import stockMovementsGrid from './stockMovementsGrid/sagas'
import suppliersGrid from './suppliersGrid/sagas'
import transfersGrid from './transfersGrid/sagas'
import ui from './ui/sagas'
import user from './user/sagas'
import users from './users/sagas'
import usersGrid from './usersGrid/sagas'
import webhooksGrid from './webhooksGrid/sagas'

export default (store: Store) =>
  function* rootSaga(): Generator {
    yield all([
      commonFetchSaga(),
      admin(),
      admins(),
      auditsGrid(),
      carriers(),
      carriersGrid(),
      complaintsGrid(),
      countries(),
      dashboardData(),
      baselinkerBatch(store),
      baselinkerCredentials(),
      eshops(),
      eshopsGrid(),
      expeditionItemsGrid(),
      expeditions(),
      expeditionsGrid(),
      filters(),
      inboundReceiptsGrid(),
      internalStockChangesGrid(),
      notificationSettingsGrid(),
      notificationsGrid(),
      missingStockGrid(),
      statusRows(),
      ordersSynchronizationGrid(),
      orderSynchronizationDetailGrid(),
      organisation(),
      organisations(),
      organisationsGrid(),
      outboundReceiptsGrid(),
      partnersGrid(),
      productStocks(),
      productStocksGrid(),
      products(),
      productsGrid(),
      productsSynchronizationGrid(),
      unsyncedProductsGrid(),
      productSynchronizationDetailGrid(),
      reportExpedition(),
      reservationsGrid(),
      returnReceiptsGrid(),
      stockAdviceItemsGrid(),
      stockAdvices(),
      stockAdvicesGrid(),
      stockChangesGrid(),
      stockMovementsGrid(),
      suppliersGrid(),
      transfersGrid(),
      ui(),
      user(),
      users(),
      usersGrid(),
      webhooksGrid(),
    ])
  }
