import React from 'react'
import { createContextualCan, CanProps } from '@casl/react'
import AbilityContext from './Context'

const ContextualCan = createContextualCan(AbilityContext.Consumer)

type Props = CanProps<any> & {
  extra: any
}

export const Can = (props: Props): JSX.Element => {
  const a = props.extra ? [props.a, props.extra] : props.a
  return <ContextualCan {...props} a={a} />
}
